import React from 'react';
import { TextField } from 'packages/shared/core';
import { useTranslation } from 'react-i18next';
import DateField from 'packages/shared/core/DateField';
import { Divider, styled, Typography } from '@mui/material';
import { useCapacitySchedule, useProviders } from 'graph';
import { FormType } from 'hooks/useForm/types';

const DividerStyled = styled(Divider)({
    margin: '50px 0',
});

const GroupNameTypographyStyled = styled(Typography)({
    marginTop: '25px',
});

const GroupWrapper = styled('div')({
    marginTop: '25px',
});

const ProvidersWrapper = styled('div')({
    marginTop: '25px',
    fontSize: 18,
});

const FieldsWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',

    '&:nth-of-type(odd)': {
        marginRight: 'auto',
    },
});

type Props = {
    form: FormType;
    id: number | string;
};

const SetUpdateForm = (props: Props) => {
    const { form, id } = props;
    const { t } = useTranslation();

    const { data: capacityScheduleData } = useCapacitySchedule({
        variables: {
            id: id,
        },
    });

    const { data: providers } = useProviders({
        variables: {
            pagination: {
                page: 0,
                pageSize: 100,
            },
        },
    });

    const capacitySchedule = capacityScheduleData?.capacitySchedule;

    return (
        <div>
            <DateField initialValue={capacitySchedule?.startDate} name='startDate' form={form} formLabel={t('general.startdate')} />

            <TextField
                name='areaManagerCapacity.capacity'
                initialValue={capacitySchedule?.areaManagerCapacity.capacity.toString()}
                type='number'
                form={form}
                label={t('areas.area-capacity')}
            />

            <TextField
                name='areaManagerCapacity.maxProviderPercentage'
                initialValue={capacitySchedule?.areaManagerCapacity.maxProviderPercentage}
                form={form}
                label={t('areas.area-percentage')}
            />

            <TextField
                name='areaManagerCapacity.usageId'
                initialValue={'deelautop'}
                hidden
                form={form}
                label={t('areas.area-percentage')}
                fullWidth
            />

            <GroupWrapper>
                <Typography variant='h2'>{t('areas.groups')}</Typography>

                {capacitySchedule?.areaGroupCapacities.map((areaGroup, index) => (
                    <div key={index}>
                        <GroupNameTypographyStyled>{areaGroup.areaGroupDescription}</GroupNameTypographyStyled>

                        <TextField
                            hidden
                            name={`areaGroupCapacities[${index}].areaGroupId`}
                            type='number'
                            readOnly
                            initialValue={areaGroup.areaGroupId}
                            form={form}
                            label={t('general.name')}
                            fullWidth
                        />

                        <TextField
                            name={`areaGroupCapacities[${index}].capacity`}
                            form={form}
                            type='number'
                            label={t('areas.capacity')}
                            fullWidth
                            initialValue={areaGroup.capacity.toString()}
                        />

                        <TextField
                            name={`areaGroupCapacities[${index}].maxProviderPercentage`}
                            form={form}
                            label={t('general.percentage')}
                            fullWidth
                            initialValue={areaGroup.maxProviderPercentage}
                        />

                        <TextField
                            name={`areaGroupCapacities[${index}].usageId`}
                            form={form}
                            label={t('general.percentage')}
                            fullWidth
                            initialValue={'deelautop'}
                            hidden
                        />

                        <DividerStyled variant='middle' textAlign='center' />
                    </div>
                ))}
            </GroupWrapper>

            <ProvidersWrapper>
                <Typography variant='h3'>{t('areas.provider-capacities')}</Typography>

                {providers?.providers?.content?.map((provider, index) => (
                    <div key={index}>
                        <FieldsWrapper>
                            <TextField
                                name={`providerCapacities[${index}].limit`}
                                type='number'
                                form={form}
                                label={provider.description + ' ' + t('areas.limit')}
                                halfWidth
                                initialValue={capacitySchedule?.areaManagerCapacity.providerCapacities.find((pc) => pc.providerId === provider.providerId)?.limit.toString() ?? ''}
                            />

                            <TextField
                                hidden
                                name={`providerCapacities[${index}].providerId`}
                                type='number'
                                readOnly
                                initialValue={provider.providerId}
                                form={form}
                                label={t('general.name')}
                                fullWidth
                            />
                        </FieldsWrapper>

                        {/*<DividerStyled variant='middle' textAlign='center' />*/}
                    </div>
                ))}
            </ProvidersWrapper>
        </div>
    );
};

export default SetUpdateForm;

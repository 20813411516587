import i18n from 'locales/i18n';
import { AreaManagerDto } from 'graph';

export const head = [
    i18n.t('general.actions'),
    i18n.t('general.id'),
    i18n.t('general.name'),
    i18n.t('general.currentCapacity'),
    i18n.t('general.maxCapacity'),
    i18n.t('general.24hours'),
    i18n.t('general.7days'),
    i18n.t('general.28days'),
];

export const rowFormat = ['id', 'currentCapacity'];

export type Item = AreaManagerDto;

export type Data = Item[];
export const normalizeRows = (data: Data) => {
    const returnArray = data.map((item: Item) => {
        return {
            id: item.id || '',
            description: item.description,
            capacity: item.capacity || 0,
            occupation: item.occupation || 0,
            overflowCount1Day: item.overflowCount1Day || 0,
            overflowCount7Days: item.overflowCount7Days || 0,
            overflowCount28Days: item.overflowCount28Days || 0,
        };
    });

    return returnArray;
};

export const actions = [i18n.t('general.delete')];

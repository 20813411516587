import React, { useState, useEffect, ChangeEvent, useMemo } from 'react';
import { InputType } from 'types';
import { FormControl, TextField as TextInput } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import classNames from 'classnames';

const FormControlStyled = styled(FormControl)((props: { label?: string }) => ({
    display: 'flex',
    marginBottom: props.label ? '5px' : '30px',

    '&.hidden': {
        display: 'none',
    },

    '&.half-width': {
        width: '48%',
    },
}));

const InputStyled = styled(TextInput)({
    width: '100%',
    height: '40px',
    color: 'black',
});

const ErrorMessage = styled('div')({
    color: 'red',
    fontSize: '14px',
});

type ExtendInputTypes = {
    className?: string;
    onChange?: (event: any) => void;
    placeholder?: string;
    suffix?: string;
    type?: string;
    hidden?: boolean;
    value?: string;
    halfWidth?: boolean;
};

type Props = InputType & ExtendInputTypes;

const InputField = (props: Props) => {
    const {
        form = false,
        label,
        name,
        onChange,
        initialValue = '',
        value: externalValue = '',
        loading,
        readOnly,
        margin = 'normal',
        placeholder,
        suffix,
        type = 'text',
        hidden = false,
    } = props;
    const { t } = useTranslation();

    //state hooks
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [value, setValue] = useState(externalValue);

    //effect hooks
    useEffect(() => {
        if (!loading) {
            setValue(initialValue);
            if (form) form?.onFieldChange({ key: name, value: initialValue });
        }
        // eslint-disable-next-line
    }, [loading, initialValue]);

    useEffect(() => {
        if (form?.errors?.[name]?.length) {
            setError(true);
            setErrorMessage(form.errors[name]);
        } else {
            setError(false);
            setErrorMessage('');
        }
    }, [form, name]);

    //functions
    const handleChange = (event: ChangeEvent<{ value: string }>) => {
        const _val = event.target.value;

        const val = suffix ? _val.replace(suffix, '') : _val;

        if (form) form.onFieldChange({ key: name, value: type !== 'number' ? val : parseInt(val) });

        if (onChange) {
            onChange(val);
        } else {
            setValue(val);
        }
    };

    const suffixValue = useMemo(() => {
        if (suffix) return `${value}${suffix}`;
        return value;
    }, [value, suffix]);

    return (
        <React.Fragment>
            <FormControlStyled
                label={label}
                error={error}
                margin={margin}
                className={classNames({ hidden: hidden, 'half-width': props.halfWidth })}
            >
                <InputStyled
                    id={`input-${name}`}
                    error={error}
                    name={name}
                    onChange={handleChange}
                    value={externalValue || suffixValue}
                    disabled={readOnly}
                    placeholder={placeholder || t('general.typeHere')}
                    type={type}
                    color="secondary"
                    label={label}
                    size="small"
                />
            </FormControlStyled>
            <ErrorMessage>{error && errorMessage}</ErrorMessage>
        </React.Fragment>
    );
};

export default InputField;

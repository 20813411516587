import { useEffect, useCallback, useMemo, useRef, useReducer } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { PaginationInput } from 'graph';
import { stateReducer } from './lib';

type Scheme = PaginationInput & { query?: string };
export type FilterType = {
    pagination: Scheme;
    query?: string;
    setPage: (page: number) => void;
    setPageSize: (data: number) => void;
    setQuery: (data: string) => void;
};

const usePagination = (scheme?: Scheme) => {
    const history = useNavigate();
    const location = useLocation();
    const searchRef = useRef(location.search);

    const initialState = useMemo(() => {
        return {
            page: scheme?.page || 0,
            pageSize: scheme?.pageSize || 10,
            sortMap: [...(scheme?.sortMap ? scheme.sortMap : [{ first: 'created', second: 'DESC' }])],
            query: '',
        };
    }, [scheme]) as Scheme;

    const [state, setState] = useReducer(stateReducer, initialState);

    const paginationFunctions = {
        setPage: (page: number) => setPage(page),
        setPageSize: (data: number) => setPageSize(data),
        setQuery: (data: string) => setQuery(data),
        pagination: {
            page: state.page,
            pageSize: state.pageSize,
            sortMap: state.sortMap,
        },
        query: state.query,
    };

    //functions
    const setQuery = (data: string) => {
        const stringified = queryString.stringify({
            page: 0,
            query: data,
            pageSize: state.pageSize,
        });

        history({ search: stringified });
    };

    const setPageSize = (data: number) => {
        const stringified = queryString.stringify({
            pageSize: data,
            query: state.query,
            page: 0,
        });

        history({ search: stringified });
    };

    const setPage = (page: number) => {
        const stringified = queryString.stringify({
            page: page,
            query: state.query,
            pageSize: state.pageSize,
        });

        history({ search: stringified });
    };

    const handleSearch = useCallback(() => {
        searchRef.current = location.search;
        const parsed = queryString.parse(location.search);

        const pageToInt = parseInt('' + parsed.page);
        const page = pageToInt === 0 ? pageToInt : pageToInt || state.page || 0;

        const obj: { page: number; pageSize: number; query: string } = {
            page: page,
            pageSize: parseInt('' + parsed.pageSize) || state.pageSize || 10,
            query: parsed.query ? parsed.query.toString() || '' : '',
        };

        setState(obj);
    }, [location.search, state.page, state.pageSize]);

    //effect hooks
    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    useEffect(() => {
        if (searchRef.current !== location.search) {
            handleSearch();
        }
    }, [location.search, searchRef, handleSearch]);

    return paginationFunctions;
};

export default usePagination;

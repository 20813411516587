import React from 'react';
import Loader from 'packages/shared/core/Loader';
import { styled } from '@mui/system';
import { OverflowDto, OverflowGroupDto } from 'graph';

export type RowType = {
    areaGroup: string[];
    description: string[];
};

type Data = {
    overflows: OverflowGroupDto[];
};

export type Props = {
    head: string[];
    loading?: boolean;
    data?: Data;
};

const DefaultCapacityTable = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    marginTop: '50px',
    overflow: 'hidden',
});

const TableHead = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '10px',
    justifyContent: 'space-between',
});

const TableHeadItem = styled('div')(({ theme }) => ({
    '&:first-of-type': {
        width: '160px',
    },

    width: '230px',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: '500',
    color: theme.palette.themePalette.primary,
}));

const TableRows = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

const TableRow = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 0',

    '&:nth-of-type(odd)': {
        background: theme.palette.backgroundPalette.primary,
        borderRadius: '6px',
    },
}));

const TableRowItem = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    width: '230px',
    fontSize: '14px',
    marginBottom: '0',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.backgroundPalette.text,

    '&:first-of-type': {
        width: '160px',
    },
}));

const OverflowTable = (props: Props) => {
    const { head, loading, data } = props;

    if (loading) return <Loader />;
    return (
        <DefaultCapacityTable>
            <TableHead>
                {head.map((name: string, idx) => (
                    <TableHeadItem key={`${idx}-${name}`}>{name}</TableHeadItem>
                ))}
            </TableHead>
            <TableRows>
                {data?.overflows.map((item: OverflowGroupDto, idx: number) => (
                    <TableRow key={idx}>
                        <TableRowItem key={idx}>{item.areaGroup.description}</TableRowItem>

                        {item?.overflows.map((i: OverflowDto, idx: number) => (
                            <TableRowItem key={idx}>{i.count}</TableRowItem>
                        ))}
                    </TableRow>
                ))}
            </TableRows>
        </DefaultCapacityTable>
    );
};

export default OverflowTable;

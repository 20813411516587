import React, { useMemo } from 'react';
import { useUsers } from 'graph';
import { usePagination } from 'hooks';
import DefaultLayout from 'packages/app/DefaultLayout';
import { head, normalizeRows, rowFormat, actions } from './tableSettings';
import PaginatedTable from 'packages/app/PaginatedTable';
import Loader from 'packages/shared/core/Loader';
import { useDialogSettings } from 'packages/shared/Context/DialogSettings';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import { dialogs } from 'lib';

const TopWrap = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '30px',
});

export type RowType = {
    id: string | number;
    [propName: string]: string | number | boolean;
};

const UsersOverview = () => {
    const pagination = usePagination();
    const dialogSettings = useDialogSettings();
    const { t } = useTranslation();

    const { data, loading } = useUsers({
        variables: {
            pagination: {
                ...pagination.pagination,
            },
            query: pagination.query,
        },
    });

    const handleDelete = (row: RowType) => {
        dialogSettings.openDialog(dialogs.deleteUser, row.id.toString());
    };

    const handleEdit = (row: RowType) => {
        dialogSettings.openDialog(dialogs.editUser, row.id.toString());
    };

    const handleActionClick = (row: RowType, action: string) => {
        switch (action) {
            case t('general.edit'):
                handleEdit(row);
                break;
            case t('general.delete'):
                handleDelete(row);
                break;
            default:
                throw new Error(t('error.general'));
        }
    };

    const handleCreateClick = () => {
        dialogSettings.openDialog(dialogs.createUser);
    };

    const rows = useMemo(() => {
        if (data?.users && data?.users.content) {
            return normalizeRows(data.users.content);
        }
        return [];
    }, [data?.users]);

    return (
        <DefaultLayout>
            <React.Fragment>
                {loading ? (
                    <Loader />
                ) : (
                    <React.Fragment>
                        <TopWrap>
                            <Typography variant="h1">{t('general.users')}</Typography>
                            <Button variant="contained" color='secondary' onClick={handleCreateClick}>
                                {t('users.add')}
                            </Button>
                        </TopWrap>
                        <PaginatedTable
                            head={head}
                            rows={rows}
                            rowFormat={rowFormat}
                            count={data?.users.content.length || 0}
                            filter={pagination}
                            onActionClick={handleActionClick}
                            actions={actions}
                        />
                    </React.Fragment>
                )}
            </React.Fragment>
        </DefaultLayout>
    );
};

export default UsersOverview;

import React, { useState, useEffect } from 'react';
import DatePicker from '@mui/lab/DatePicker';
import { FormControl, FormHelperText, TextField } from '@mui/material';
import { FormLabel } from 'packages/shared/core';
import { styled } from '@mui/system';

const FormControlStyled = styled(FormControl)({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '5px',
    flexDirection: 'row',
    alignItems: 'center',
    height: '40px',
});

const InputStyled = styled(TextField)({
    width: '100%',
    height: '40px',

    '& .MuiOutlinedInput-root': {
        height: '40px',
    },
});

const PickerStyled = styled(DatePicker)({
    width: '100%',
    height: '40px',
});

type Props = {
    label?: string;
    loading?: boolean;
    initialValue?: Date | null;
    format?: string;
    form?: any;
    startDate?: Date;
    maxDate?: Date;
    minDate?: Date;
    hint?: boolean;
    name?: string;
    startToday?: boolean;
    fullWidth?: boolean;
    className?: string;
    readOnly?: boolean;
    onChange?: (event: { key: string; value: Date | null }) => void;
    margin?: 'dense' | 'none' | 'normal';
    formLabel?: string;
    placeHolder?: string;
    disablePast?: boolean;
};

const DateField = (props: Props) => {
    const {
        label = '',
        loading = false,
        initialValue = null,
        format = 'dd-MM-yyyy',
        form,
        name = '',
        formLabel,
        maxDate,
        minDate,
        startToday = false,
        readOnly,
        margin = 'dense',
        disablePast = false,
    } = props;

    const [selectedDate, setSelectedDate] = useState<Date | null>(initialValue);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (form && form.errors && form?.errors[name] && form?.errors[name].length) {
            setError(true);
            setErrorMessage(form.errors[name]);
        } else {
            setError(false);
            setErrorMessage('');
        }
    }, [form, name]);

    useEffect(() => {
        if (form && initialValue && !loading) {
            handleDateChange(initialValue);
        }

        // eslint-disable-next-line
    }, [loading, initialValue]);

    const handleDateChange = (date: unknown) => {
        if(date instanceof Date) {
            if (form) form.onFieldChange({ key: name, value: date });

            if (props.onChange) {
                props.onChange({ key: name, value: date });
            } else {
                setSelectedDate(date);
            }
        }
    };

    return (
        <FormControlStyled error={error} margin={margin}>
            {formLabel && <FormLabel gutterBottom>{formLabel}</FormLabel>}
            <PickerStyled
                maxDate={maxDate}
                minDate={minDate}
                label={label}
                disabled={readOnly}
                inputFormat={format}
                value={selectedDate || (startToday ? new Date() : null)}
                renderInput={(params) => <InputStyled {...params} />}
                onChange={(date: unknown) => handleDateChange(date)}
                disablePast={disablePast}
                mask={'__-__-____'}
            />
            <div>{error && <FormHelperText>{error && errorMessage}</FormHelperText>}</div>
        </FormControlStyled>
    );
};

export default DateField;

import React from 'react';
import { TextField } from 'packages/shared/core';
import { useTranslation } from 'react-i18next';

type Props = {
    form: any;
    initial?: any;
};

const AddUserForm = (props: Props) => {
    const { form, initial } = props;
    const { t } = useTranslation();

    return (
        <div>
            <TextField name="firstName" form={form} label={t('general.firstname')} fullWidth initialValue={initial?.firstName || ''} />

            <TextField name="lastName" form={form} label={t('general.lastname')} fullWidth initialValue={initial?.lastName || ''} />

            <TextField name="email" form={form} label={t('general.email')} fullWidth initialValue={initial?.email || ''} />
        </div>
    );
};

export default AddUserForm;

import React, { useMemo } from 'react';
import DefaultLayout from 'packages/app/DefaultLayout';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useMessages } from 'graph';
import { usePagination } from 'hooks';
import { normalizeRows, head, rowFormat } from './tableSettings';
import PaginatedTable from 'packages/app/PaginatedTable';
import Loader from 'packages/shared/core/Loader';

const TopWrap = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '40px',
});

const Messages = () => {
    const { t } = useTranslation();
    const pagination = usePagination();

    const { data, loading } = useMessages({
        variables: {
            pagination: {
                ...pagination.pagination,
                page: 0,
                pageSize: 99,
            },
        },
    });

    const rows = useMemo(() => {
        if (data?.messages && data?.messages?.content) {
            return normalizeRows(data.messages.content);
        }
        return [];
    }, [data?.messages]);

    if (loading) return <Loader />;
    return (
        <DefaultLayout>
            <React.Fragment>
                <TopWrap>
                    <Typography variant="h1">{t('general.messages')}</Typography>
                </TopWrap>
                <PaginatedTable head={head} rows={rows} rowFormat={rowFormat} count={rows.length || 0} filter={pagination} />
            </React.Fragment>
        </DefaultLayout>
    );
};

export default Messages;

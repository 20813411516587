import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { client } from 'client';
import keycloak from 'client/keycloak';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { BrowserRouter as Router } from 'react-router-dom';
import App from 'components/App';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'packages/shared/Context/Snackbar';
import theme from 'styles/theme';
import { Settings } from 'luxon';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Loader from 'packages/shared/core/Loader';

Settings.defaultZone = 'Europe/Amsterdam';

const Root = () => {
    return (
        <ReactKeycloakProvider
            authClient={keycloak}
            isLoadingCheck={(keycloak) => !keycloak.authenticated}
            initOptions={{
                onLoad: 'login-required',
            }}
            LoadingComponent={<Loader />}
        >
            <SnackbarProvider>
                <ThemeProvider theme={theme}>
                    <ApolloProvider client={client}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Router>
                                <App />
                            </Router>
                        </LocalizationProvider>
                    </ApolloProvider>
                </ThemeProvider>
            </SnackbarProvider>
        </ReactKeycloakProvider>
    );
};

export default Root;

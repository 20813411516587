import React, { ReactNode, useState, useEffect } from 'react';
import Menu from '../Menu/Menu';
import logo from 'assets/flexity-logo.svg';
import keycloak from 'client/keycloak';
import { styled } from '@mui/system';
import Avatar from '@mui/material/Avatar';
import LogoutIcon from '@mui/icons-material/Logout';

type Props = {
    children?: ReactNode;
};

const DefaultLayoutRoot = styled('div')(({ theme }) => ({
    margin: '0 auto',
    minHeight: '90vh',
}));

const TopWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.themePalette.primary,
    flexWrap: 'wrap',
}));

const DefaultContainer = styled('div')(({ theme }) => ({
    maxWidth: '1260px',
    padding: '0 25px',
    width: '100%',
    margin: '0 auto',
}));

const TopContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    paddingTop: '20px',
}));

const ContentContainer = styled('div')(({ theme }) => ({
    maxWidth: '1260px',
    padding: '60px 25px',
    width: '100%',
    margin: '0 auto',
}));

const PersonWrap = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.themePalette.white,
    fontSize: '14px',
}));

const Logout = styled(LogoutIcon)(({ theme }) => ({
    '&:hover': {
        color: theme.palette.themePalette.secondary,
        cursor: 'pointer',
    },
}));

const StyledLogo = styled(Avatar)({
    width: '145px',
    height: '45px',

    '& img': {
        objectFit: 'contain',
    },
});

const Name = styled('div')({
    marginRight: '20px',
    opacity: '0.5',
});

const DefaultLayout = (props: Props) => {
    const [name, setName] = useState('');
    const { children } = props;

    //effect hooks
    useEffect(() => {
        keycloak.loadUserInfo().then((r: any = { name }) => {
            setName(r.name);
        });
    }, [name]);

    const handleLogout = () => {
        keycloak.logout();
    };

    return (
        <DefaultLayoutRoot>
            <TopWrapper>
                <DefaultContainer>
                    <TopContainer>
                        <StyledLogo variant="square" alt="logo" src={logo} />

                        <PersonWrap>
                            <Name>{name}</Name>
                            <Logout onClick={handleLogout} />
                        </PersonWrap>
                    </TopContainer>
                    <Menu />
                </DefaultContainer>
            </TopWrapper>
            <ContentContainer>
                <main>{children}</main>
            </ContentContainer>
        </DefaultLayoutRoot>
    );
};

export default DefaultLayout;

import React, { useState } from 'react';
import { TextField } from 'packages/shared/core';
import { useTranslation } from 'react-i18next';
import { Button, styled, Typography } from '@mui/material';
import { useForm } from 'hooks';
import { useCreateArea } from 'graph';
import { useSnackbar } from 'packages/shared/Context/Snackbar';
import { useDialogSettings } from 'packages/shared/Context/DialogSettings';

const AreaFormStyled = styled('div')(({ theme }) => ({
    padding: '20px 0',
    borderBottom: `1px solid ${theme.palette.divider}`,
}));

const ButtonStyled = styled(Button)({
    marginTop: '15px',
});

const ButtonWrapper = styled('div')({
    textAlign: 'right',
});

type Props = {
    onSetShowAreaForm: (show: boolean) => void;
    areaManagerId?: string;
};

const AddAreaForm = (props: Props) => {
    const { onSetShowAreaForm, areaManagerId } = props;
    const [form] = useForm({
        areaId: true,
        description: true,
    });
    const { t } = useTranslation();
    const [areaIdValue, setAreaIdValue] = useState('');
    const [descriptionValue, setDescriptionValue] = useState('');
    const dialogSettings = useDialogSettings();

    //mutation
    const [mutate] = useCreateArea({
        refetchQueries: ['areas'],
    });

    //snackbars
    const snackbarError = useSnackbar({
        message: t('error.general'),
        severity: 'error',
    });
    const snackbarSucces = useSnackbar({
        message: t('area.success'),
        severity: 'success',
    });

    const handleAreaIdValueChange = (value: string) => {
        setAreaIdValue(value);
    };

    const handleDescriptionValueChange = (value: string) => {
        setDescriptionValue(value);
    };

    const handleClick = () => {
        form.onSubmit()
            .then((result: any) => {
                mutate({
                    variables: {
                        input: {
                            ...result,
                            areaManagerId: areaManagerId,
                        },
                    },
                })
                    .then((result) => {
                        if (result?.data?.createArea?.areaId) {
                            snackbarSucces.onSetAlert();
                            form.onReset();
                            setAreaIdValue('');
                            setDescriptionValue('');
                            onSetShowAreaForm(false);
                        }
                    })
                    .catch(() => {
                        snackbarError.onSetAlert();
                    });
            })
            .catch((e) => {
                snackbarError.onSetAlert();
            });
    };

    return (
        <AreaFormStyled>
            <Typography variant="h2">{t('area.add')}</Typography>

            <TextField value={areaIdValue} onChange={handleAreaIdValueChange} name="areaId" form={form} label={t('area.id')} fullWidth />

            <TextField
                value={descriptionValue}
                onChange={handleDescriptionValueChange}
                name="description"
                form={form}
                label={t('general.description')}
                fullWidth
            />

            <ButtonWrapper>
                <ButtonStyled onClick={handleClick} variant="contained" color="primary">
                    {t('area.add')}
                </ButtonStyled>
            </ButtonWrapper>
        </AreaFormStyled>
    );
};

export default AddAreaForm;

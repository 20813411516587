import React from 'react';
import { styled } from '@mui/system';
import { Typography } from '@mui/material';
import ActionMenu from 'packages/app/ActionMenu';
import { AreaGroupCapacityDto, AreaManagerCapacityDto } from 'graph';
import { toLocalDate } from 'lib';
import { useTranslation } from 'react-i18next';

export type RowType = AreaGroupCapacityDto;

export type ManagerType = AreaManagerCapacityDto;

type ActionCallback = (row: string, action: string) => void;
export type Props = {
    groupRows?: RowType[];
    head: string[];
    actions?: string[];
    manager?: ManagerType;
    endDate?: string;
    setId: string;
    onActionClick?: ActionCallback;
    onRowActionClick?: ActionCallback;
};

const DefaultCapacityTable = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
    overflow: 'hidden',
    background: theme.palette.backgroundPalette.primary,
    padding: '50px',
    borderRadius: '10px',
}));

const TableHead = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '10px',
});

const TableHeadItem = styled('div')(({ theme }) => ({
    width: '200px',
    textAlign: 'left',
    fontWeight: '500',
    color: theme.palette.primary.main,
    fontSize: '15px',
    padding: '0 15px',

    '&:nth-of-type(2)': {
        width: '440px',
        textAlign: 'left',
    },

    '&:last-child': {
        width: '40px',
    },
}));

const TableRows = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

const TitleWrap = styled('div')({
    marginBottom: '25px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '15px',
});

const TopWrap = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '30px',
    paddingLeft: '15px',
});

const BottomWrap = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '30px',
    paddingLeft: '15px',
});

const TopRow = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    width: '300px',
    justifyContent: 'space-between',
});

const TableRowItem = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    padding: '20px 15px',
    color: theme.palette.primary.main,
    fontSize: '14px',

    '&:nth-of-type(odd)': {
        background: theme.palette.backgroundPalette.light,
        borderRadius: '6px',
    },

    '&:last-of-type': {
        borderBottom: 'none',
    },
}));

const RowItemCell = styled('div')({
    width: '200px',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',


    '&:nth-of-type(2)': {
        width: '450px',
        textAlign: 'left',
    },

    '&:last-child': {
        width: '40px',
    },
});

const SetsTable = (props: Props) => {
    const { groupRows, head, manager, actions, setId, onActionClick, onRowActionClick, endDate } = props;
    const { t } = useTranslation();

    const handleClick = (row: string) => (action: string) => {
        if (onActionClick) onActionClick(row, action);
    };

    const handleRowClick = (row: string) => (action: string) => {
        if (onRowActionClick) onRowActionClick(row, action);
    };

    const SetsTableStyled = styled('div')({
        margin: '0 0 50px',
        opacity: props.endDate ? 0.5 : 1,
    });

    const providerCapacities = props.manager?.providerCapacities ? [...props.manager?.providerCapacities]?.sort((pc1, pc2) => pc1.providerId > pc2.providerId ? 1 : -1) : [];

    return (
        <SetsTableStyled>
            <DefaultCapacityTable>
                <TitleWrap>
                    <Typography variant='h3'>
                        {t('general.from')} {toLocalDate(manager?.startDate, 'dd MMMM yyyy')}
                        {endDate && (
                            <React.Fragment>
                                {' ' + t('general.until')} {toLocalDate(endDate, 'dd MMMM yyyy')}
                            </React.Fragment>
                        )}
                    </Typography>

                    {actions && <ActionMenu actions={actions} onClick={handleClick(setId)} fontSize='large' />}
                </TitleWrap>
                <TopWrap>
                    <TopRow>
                        <Typography variant='body1'>{t('areas.totalcapacity')}</Typography>
                        <Typography variant='subtitle1'>{manager?.capacity}</Typography>
                    </TopRow>
                    <TopRow>
                        <Typography variant='body1'>{t('areas.area_manager-capacity')}</Typography>
                        <Typography variant='subtitle1'>{manager?.maxProviderPercentage}%</Typography>
                    </TopRow>
                </TopWrap>
                <TableHead>
                    {head.map((name: string, idx) => (
                        <TableHeadItem key={`${idx}-${name}`}>{name}</TableHeadItem>
                    ))}
                </TableHead>
                <TableRows>
                    {groupRows?.map((row, idx) => (
                        <TableRowItem key={idx}>
                            <RowItemCell>{row.areaGroupDescription}</RowItemCell>
                            <RowItemCell>{row.areaIds.join(', ')}</RowItemCell>
                            <RowItemCell>{row.maxProviderPercentage}%</RowItemCell>
                            <RowItemCell>{row.capacity}</RowItemCell>
                            <RowItemCell>
                                {props.endDate ? undefined : (
                                    <ActionMenu actions={[t('general.edit')]} onClick={handleRowClick(row.areaGroupId)} fontSize='medium' />
                                )}
                            </RowItemCell>
                        </TableRowItem>
                    ))}
                </TableRows>

                <BottomWrap>
                    <Typography variant='h3'>
                        {t('areas.provider-capacities')}
                    </Typography>
                    {providerCapacities.length != 0 ?
                        <>
                            {providerCapacities.map((providerCapacity, idx) => (
                                <TopRow key={idx}>
                                    <Typography variant='body1'>{providerCapacity.providerId}</Typography>
                                    <Typography variant='subtitle1'>{providerCapacity.limit}</Typography>
                                </TopRow>
                            ))}
                        </>
                        : <Typography variant='subtitle1'>{t("areas.no-limit")}</Typography>
                    }
                </BottomWrap>

            </DefaultCapacityTable>
        </SetsTableStyled>
    );
};

export default SetsTable;

import React from 'react';
import Dialog from 'packages/shared/Dialog';
import { useTranslation } from 'react-i18next';
import { asStartOfDayISOString, dialogs } from 'lib';
import { CancelAuthorisationForm }  from 'packages/app/forms';
import {useForm} from "../../../hooks";
import {useCancelAuthorisation} from "../../../graph";
import {useSnackbar} from "../../../packages/shared/Context/Snackbar";
import {useDialogSettings} from "../../../packages/shared/Context/DialogSettings";
import { DateTime } from 'luxon';

const CancelAuthorisationDialog = () => {
    const { t } = useTranslation();

    const [form] = useForm({
        endDate: true,
    });
    const dialogSettings = useDialogSettings();
    const paymentAuthorisationId = dialogSettings.getData();

    //mutation
    const [mutate] = useCancelAuthorisation({
        refetchQueries: ['authorisations']
    });

    //snackbars
    const snackbarError = useSnackbar({
        message: t('error.general'),
        severity: 'error',
    });
    const snackbarSucces = useSnackbar({
        message: t('authorisations.success'),
        severity: 'success',
    });

    const handleClick = () => {
        form.onSubmit().then((result: any) => {
            const endDate = DateTime.fromJSDate(result.endDate);
            mutate({
                variables: {
                    input: {
                        endDate: asStartOfDayISOString(endDate),
                        paymentAuthorisationId
                    },
                },
            })
                .then((result) => {
                    if (result?.data?.cancelAuthorisation) {
                        snackbarSucces.onSetAlert();
                        form.onReset();
                        dialogSettings.closeDialog();
                    }
                })
                .catch((e) => {
                    snackbarError.onSetAlert();
                });

        }).catch((e) => {
            snackbarError.onSetAlert();
        });
    }

    const handleClose = () => {
        form.onReset();
    };

    return (
        <Dialog
            title={t('authorisations.cancel-form.title')}
            name={dialogs.cancelAuthorisations}
            acceptName={t('general.confirm')}
            onClick={handleClick}
            onClose={handleClose}
        >
            <CancelAuthorisationForm form={form} />
        </Dialog>
    );
};

export default CancelAuthorisationDialog;

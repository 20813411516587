import React, { useMemo } from 'react';
import { useProviders } from 'graph';
import { usePagination } from 'hooks';
import DefaultLayout from 'packages/app/DefaultLayout';
import { head, normalizeRows, rowFormat, actions } from './tableSettings';
import PaginatedTable from 'packages/app/PaginatedTable';
import Loader from 'packages/shared/core/Loader';
import DeleteProviderDialog from './DeleteProviderDialog';
import { useDialogSettings } from 'packages/shared/Context/DialogSettings';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import { dialogs } from 'lib';

const TopWrap = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '30px',
});

export type RowType = {
    id: string | number;
    [propName: string]: string | number | boolean;
};

const Providers = () => {
    const pagination = usePagination();
    const dialogSettings = useDialogSettings();
    const { t } = useTranslation();

    const { data, loading } = useProviders({
        variables: {
            pagination: {
                ...pagination.pagination,
            },
            query: pagination.query,
        },
    });

    const handleDelete = (row: RowType) => {
        dialogSettings.openDialog(dialogs.deleteProvider, row.id.toString());
    };

    const handleCreateClick = () => {
        dialogSettings.openDialog(dialogs.createProvider);
    };

    const rows = useMemo(() => {
        if (data?.providers && data?.providers.content) {
            return normalizeRows(data.providers.content);
        }
        return [];
    }, [data?.providers]);

    return (
        <DefaultLayout>
            <React.Fragment>
                {loading ? (
                    <Loader />
                ) : (
                    <React.Fragment>
                        <TopWrap>
                            <Typography variant="h1">{t('general.sharecarproviders')}</Typography>
                            <Button variant="contained" color='secondary' onClick={handleCreateClick}>
                                {t('provider.add')}
                            </Button>
                        </TopWrap>
                        <PaginatedTable
                            head={head}
                            rows={rows}
                            rowFormat={rowFormat}
                            count={data?.providers.totalElements || 0}
                            filter={pagination}
                            onActionClick={handleDelete}
                            actions={actions}
                        />
                    </React.Fragment>
                )}
                <DeleteProviderDialog />
            </React.Fragment>
        </DefaultLayout>
    );
};

export default Providers;

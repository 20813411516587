import React from 'react';
import Router from 'components/Router';
import { DialogSettingsProvider } from 'packages/shared/Context/DialogSettings';
import Dialogs from 'components/Dialogs';

const App = () => {
    return (
        <DialogSettingsProvider>
            <Router />
            <Dialogs />
        </DialogSettingsProvider>
    );
};

export default App;

import React, { useMemo, useEffect } from 'react';
import Dialog from 'packages/shared/Dialog';
import { useTranslation } from 'react-i18next';
import { useDialogSettings } from 'packages/shared/Context/DialogSettings';
import { useSnackbar } from 'packages/shared/Context/Snackbar';
import { useDeleteCapacitySchedule } from 'graph';
import { Typography } from '@mui/material';
import { dialogs } from 'lib';

const DeleteSetDialog = () => {
    const { t } = useTranslation();
    const dialogSettings = useDialogSettings();

    const dialogId = useMemo(() => {
        return dialogSettings.getData();
    }, [dialogSettings]);

    //snackbars
    const snackbarError = useSnackbar({
        message: t('error.general'),
        severity: 'error',
    });
    const snackbarSucces = useSnackbar({
        message: t('areas.deletesuccess'),
        severity: 'success',
    });

    //mutation
    const [mutate, mutationData] = useDeleteCapacitySchedule({
        refetchQueries: ['capacitySchedulesByAreaManager'],
    });

    const handleClick = () => {
        mutate({
            variables: {
                id: dialogId,
            },
        });
    };
    //use effect
    useEffect(() => {
        if (!mutationData.loading) {
            if (mutationData.error) {
                snackbarError.onSetAlert();
                dialogSettings.closeDialog();
            }
            if (mutationData.data && mutationData.data.deleteCapacitySchedule && mutationData.data.deleteCapacitySchedule) {
                snackbarSucces.onSetAlert({
                    message: t('areas.deletesuccess'),
                });
                dialogSettings.closeDialog();
            }
        }
        // eslint-disable-next-line
    }, [mutationData.data, mutationData.error, mutationData.loading]);

    return (
        <Dialog title={t('areas.deleteset')} name={dialogs.deleteSet} onClick={handleClick} acceptName={t('general.delete')}>
            <Typography variant="h4" gutterBottom>
                {t('general.warning')}
            </Typography>
            <Typography variant="body1">{t('areas.deletesetDialogContent')}</Typography>
        </Dialog>
    );
};

export default DeleteSetDialog;

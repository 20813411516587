/* eslint-disable no-use-before-define */
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Autocomplete, AutocompleteProps, Chip, FormControl, FormHelperText, TextField } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { FormLabel } from 'packages/shared/core';

const StyledAutoCompleteType = <
    T,
    Multiple extends boolean | undefined = undefined,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined
>(
    props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>
) => {
    return <AutoCompleteStyled {...props} />;
};

const FormControlStyled = styled(FormControl)({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '5px',
});

const InputStyled = styled(TextField)({
    width: '100%',
    height: '40px',
});

const AutoCompleteStyled = styled(Autocomplete)({
    width: '100%',
    height: '40px',
    '& .MuiInputBase-input': {
        height: '8px',
    },
}) as typeof Autocomplete;

const ChipsWrapper = styled('div')({
    marginTop: '30px',
});

const ChipStyled = styled(Chip)(({ theme }) => ({
    marginRight: '10px',
    marginBottom: '10px',
    backgroundColor: theme.palette.themePalette.primary,
    color: theme.palette.themePalette.white,

    '& .MuiChip-deleteIcon': {
        color: theme.palette.themePalette.white,
    },
    '& .MuiChip-deleteIcon:hover': {
        color: theme.palette.themePalette.greyedOut,
    },
}));

export type Option = { value: string | number; label: string; disabled?: boolean };

type Props = {
    name: string;
    options: Option[];
    form?: any;
    initialValue?: Option[];
    loading?: boolean;
    onChange?: (value: Option[]) => void;
    label?: string;
    optionLabelName?: string;
    placeholder?: string;
};

const MultiSelectField = (props: Props) => {
    const { form = false, loading, onChange, name, initialValue = [], label, placeholder, options } = props;

    const { t } = useTranslation();

    const [hasError, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [val, setVal] = useState<Option[]>([]);

    useEffect(() => {
        if (form?.errors?.[name]?.length) {
            setError(true);
            setErrorMessage(form.errors[name]);
        } else {
            setError(false);
            setErrorMessage('');
        }
    }, [form, form.errors, name, initialValue, setVal]);

    const handleChange = (event: any, value: Option[]) => {
        setVal(value);
        if (form) form.onFieldChange({ key: name, value: value });
        onChange?.(val);
    };

    const handleChipDelete = (deletedOption: Option) => (event: SyntheticEvent) => {
        const filtered = val.filter((option: Option) => option.value !== deletedOption.value);
        setVal(filtered);
        if (form) form.onFieldChange({ key: name, value: filtered });
    };

    useEffect(() => {
        if (!loading && initialValue.length > 0) {
            setVal(initialValue);
            if (form) form?.onFieldChange({ key: name, value: initialValue });
        }
        // eslint-disable-next-line
    }, [loading, initialValue]);

    const valHtml = val.map((option, index) => {
        const label = option?.label || '-';
        return <ChipStyled key={index} label={label} deleteIcon={<CancelIcon />} onDelete={handleChipDelete(option)} color="primary" />;
    });

    return (
        <React.Fragment>
            <FormLabel gutterBottom>{label}</FormLabel>
            <FormControlStyled error={hasError} margin={'dense'}>
                <StyledAutoCompleteType
                    multiple
                    filterSelectedOptions
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    options={options || []}
                    renderTags={() => null}
                    onChange={handleChange}
                    getOptionLabel={(option) => option.label}
                    value={val}
                    renderInput={(params) => <InputStyled placeholder={placeholder || t('general.typeHere')} {...params} name={name} />}
                />
                <ChipsWrapper>{valHtml}</ChipsWrapper>
                {hasError && <FormHelperText>{errorMessage}</FormHelperText>}
            </FormControlStyled>
        </React.Fragment>
    );
};

export default MultiSelectField;

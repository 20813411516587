import i18n from 'locales/i18n';
import {AuthorisationDto} from "../../graph";
import {toLocalDate} from "../../lib";

export const head = [
    i18n.t('authorisations.table-header.payment-authorisation-id'),
    i18n.t('authorisations.table-header.vehicle-id'),
    i18n.t('authorisations.table-header.provider-id'),
    i18n.t('authorisations.table-header.area-id'),
    i18n.t('authorisations.table-header.start-date'),
    i18n.t('authorisations.table-header.end-date'),
];

export const rowFormat = ['id', 'vehicleId', 'providerId', 'areaId', 'startDate', 'endDate'];

export type Data = AuthorisationDto[];
export const normalizeRows = (data: Data) => {
    const returnArray = data.map((item: AuthorisationDto) => {
        return {
            id: item.paymentAuthorisationId,
            vehicleId: item.vehicleId || '',
            providerId: item.providerId,
            areaId: item.areaId,
            startDate: toLocalDate(item.startDate),
            endDate: item.endDate ? toLocalDate(item.endDate) : ''
        };
    });

    return returnArray;
};

export const actions = [i18n.t('authorisations.cancel')];

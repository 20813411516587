import i18n from 'locales/i18n';

export const head = [
    i18n.t('general.providerId'),
    i18n.t('general.description'),
];

export const rowFormat = ['providerId', 'description'];

export type Item = {
    providerId: string;
    id?: number | string;
    description: string;
};

export type Data = Item[];
export const normalizeRows = (data: Data) => {
    const returnArray = data.map((item: Item) => {
        return {
            providerId: item.providerId,
            id: item.id || '',
            description: item.description,
        };
    });

    return returnArray;
};

export const actions = [i18n.t('general.delete')];

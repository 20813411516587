import { PaginationInput } from 'graph';

type Scheme = PaginationInput & { query?: string };
export const stateReducer = (
    state: Scheme,
    action: { page: number; pageSize: number; query?: string }
) => {
    if (action) {
        let obj = {
            ...state,
            ...action,
            query: action.query || '',
        };

        return obj;
    }

    return state;
};

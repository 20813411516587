import i18n from 'locales/i18n';
import { MessageDto } from 'graph';
import { toLocalDate } from 'lib';

export const head = [i18n.t('general.id'), i18n.t('general.date'), i18n.t('messages.request'), i18n.t('messages.response')];

export const rowFormat = ['id', 'date', 'request', 'response'];

export type Item = MessageDto;

export type Data = Item[];
export const normalizeRows = (data: Data) => {
    const returnArray = data.map((item: Item) => {
        return {
            id: item.id || '',
            date: toLocalDate(item?.date),
            request: item.request,
            response: item.response,
        };
    });

    return returnArray;
};

export const actions = [i18n.t('general.delete')];

import React from 'react';
import CreateProviderDialog from './CreateProviderDialog';
import CreateAreasDialog from './CreateAreasDialog';
import CreateUserDialog from './CreateUserDialog';
import EditUserDialog from './EditUserDialog';
import DeleteUserDialog from './DeleteUserDialog';
import CreateSetDialog from './CreateSetDialog';
import CreateGroupDialog from './CreateGroupDialog';
import EditGroupDialog from './EditGroupDialog/EditGroupDialog';
import DeleteSetDialog from './DeleteSetDialog';
import EditSetDialog from './EditSetDialog';
import CancelAuthorisationDialog from './CancelAuthorisationDialog';

const Dialogs = () => {
    return (
        <React.Fragment>
            <CreateProviderDialog />
            <CreateAreasDialog />
            <CreateUserDialog />
            <EditUserDialog />
            <DeleteUserDialog />
            <CreateSetDialog />
            <CreateGroupDialog />
            <EditGroupDialog />
            <DeleteSetDialog />
            <EditSetDialog />
            <CancelAuthorisationDialog />
        </React.Fragment>
    );
};

export default Dialogs;

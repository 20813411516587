import React from 'react';
import { List, ListItemButton } from '@mui/material';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';

type ListItemObject = {
    name: string;
    path?: string;
};

type Props = {
    list: ListItemObject[];
};

const StyledList = styled(List)(({ theme }) => ({
    display: 'flex',
    borderBottom: `1px solid ${theme.palette.themePalette.secondary}`,
    padding: '0',

    '.listItem': {
        padding: '10px 0',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        borderBottom: '4px solid transparent',
        color: theme.palette.themePalette.white,
        fontSize: '15px',
    },

    '.Mui-selected': {
        background: 'none',
        fontWeight: '500',
        backgroundColor: theme.palette.themePalette.secondary
    },

    '.listItem:hover': {
        background: 'none',
        borderBottom: `4px solid ${theme.palette.themePalette.secondary}`,
    },

    '.Mui-selected:hover': {
        background: 'none',
        fontWeight: '500',
        backgroundColor: theme.palette.themePalette.secondary
    },
}));

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
    padding: '10px 0',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    borderBottom: '4px solid transparent',
    color: theme.palette.themePalette.white,
    fontSize: '15px',

    '&.Mui-selected': {
        background: 'none',
        fontWeight: '500',
        backgroundColor: theme.palette.themePalette.secondary
    },

    '&:hover': {
        background: 'none',
        borderBottom: `4px solid ${theme.palette.themePalette.secondary}`,
    },

    '&.Mui-selected:hover': {
        background: 'none',
        fontWeight: '500',
        backgroundColor: theme.palette.themePalette.secondary
    },
})) as typeof ListItemButton;

const ListContainer = (props: Props) => {
    const { list } = props;
    const location = useLocation();

    return (
        <StyledList>
            {list &&
                list.map((item: ListItemObject) => {
                    return (
                        <StyledListItemButton
                            key={item.name}
                            selected={location.pathname === item.path}
                            component={Link}
                            to={item.path || '/'}
                            alignItems="center"
                            className="listItem"
                        >
                            {item.name}
                        </StyledListItemButton>
                    );
                })}
        </StyledList>
    );
};

export default ListContainer;

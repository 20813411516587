import React from 'react';
import Dialog from 'packages/shared/Dialog';
import { useTranslation } from 'react-i18next';
import { AddProviderForm } from 'packages/app/forms';
import { useForm } from 'hooks';
import { useDialogSettings } from 'packages/shared/Context/DialogSettings';
import { useSnackbar } from 'packages/shared/Context/Snackbar';
import { useCreateProvider } from 'graph';
import { dialogs } from 'lib';

const CreateProviderDialog = () => {
    const { t } = useTranslation();
    const [form] = useForm({
        description: true,
        providerId: true,
    });
    const dialogSettings = useDialogSettings();

    //snackbars
    const snackbarError = useSnackbar({
        message: t('error.general'),
        severity: 'error',
    });
    const snackbarSucces = useSnackbar({
        message: t('provider.success'),
        severity: 'success',
    });

    //mutation
    const [mutate] = useCreateProvider({
        refetchQueries: ['providers'],
    });

    //functions
    const handleClick = () => {
        form.onSubmit()
            .then((result) => {
                mutate({
                    variables: {
                        input: { ...(result as { description: string; providerId: string }) },
                    },
                })
                    .then((result) => {
                        if (result.data && result.data.createProvider && result.data.createProvider.id) {
                            snackbarSucces.onSetAlert();
                            dialogSettings.closeDialog();
                        }
                    })
                    .catch(() => {
                        snackbarError.onSetAlert();
                    });
            })
            .catch((e) => {
                snackbarError.onSetAlert();
            });
    };

    const handleClose = () => {
        form.onReset();
    };

    return (
        <Dialog
            title={t('provider.createProviderTitle')}
            name={dialogs.createProvider}
            onClick={handleClick}
            onClose={handleClose}
            acceptName={t('general.create')}
        >
            <AddProviderForm form={form} />
        </Dialog>
    );
};

export default CreateProviderDialog;

import React, { useState, useEffect } from 'react';
import { TextField } from 'packages/shared/core';
import { useTranslation } from 'react-i18next';
import { MultiSelectField } from 'packages/shared/core';
import { useAreas } from 'graph';
import { usePagination } from 'hooks';
import { FormControlLabel, Switch, Collapse } from '@mui/material';
import AddAreaForm from 'packages/app/forms/AddAreaForm';
import { Option } from 'packages/shared/core/MultiSelectField';
import { FormType } from 'hooks/useForm/types';
import { useParams } from 'react-router';

type Props = {
    form: FormType;
    areaManagerId: string;
};

const AddGroupForm = (props: Props) => {
    const { form, areaManagerId } = props;
    const { t } = useTranslation();
    const pagination = usePagination();
    const [options, setOptions] = useState<Option[]>([]);
    const newAreaText = t('area.new');

    const { data: areasData, loading } = useAreas({
        variables: {
            pagination: {
                pageSize: 9999,
                page: 0,
            },
            filter: {
                onlyNotLinked: true,
            },
        },
    });

    useEffect(() => {
        if (areasData?.areas?.content?.length && !loading) {
            const mapAreasData = areasData.areas.content
                .filter((area) => area.areaManagerId === areaManagerId)
                .map((area) => {
                    return {
                        label: `${area.areaId || ''}-${area.description}`,
                        value: area.id,
                    };
                });
            setOptions(mapAreasData);
        }
    }, [areasData, loading]);

    const [showAreaForm, setShowAreaForm] = useState<boolean>(false);

    const handleShowAreaFormChange = () => {
        setShowAreaForm((prev) => !prev);
    };

    const handleShowAreaForm = (show: boolean) => {
        setShowAreaForm(show);
    };

    return (
        <div>
            <TextField className="test" name="description" form={form} label={t('general.name')} fullWidth />

            <MultiSelectField
                name="areas"
                form={form}
                options={options}
                optionLabelName={'areaId'}
                placeholder={t('areas.codes')}
                label={''}
            />

            <FormControlLabel control={<Switch checked={showAreaForm} onChange={handleShowAreaFormChange} />} label={newAreaText} />

            <Collapse in={showAreaForm}>
                <AddAreaForm onSetShowAreaForm={handleShowAreaForm} areaManagerId={areaManagerId} />
            </Collapse>
        </div>
    );
};

export default AddGroupForm;

import React, { useMemo } from 'react';
import DefaultLayout from 'packages/app/DefaultLayout';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import { useDialogSettings } from 'packages/shared/Context/DialogSettings';
import { styled } from '@mui/system';
import { useParams } from 'react-router';
import { normalizeRows, actions, head } from './tableSettings';
import { useAreaManager, useCapacitySchedulesByAreaManager } from 'graph';
import SetsTable from './SetsTable';
import { dialogs } from 'lib';
import { DateTime } from 'luxon';

const TopWrap = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '50px',
});

const ButtonStyled = styled(Button)({
    marginLeft: '20px',
});

const ButtonWrapStyled = styled('div')({
    display: 'flex',
});

const AreaDetail = () => {
    const { t } = useTranslation();
    const dialogSettings = useDialogSettings();
    const { id } = useParams<Record<string, string | undefined>>();

    const { data } = useAreaManager({
        variables: {
            id: id || '',
        },
    });

    const { data: schedules } = useCapacitySchedulesByAreaManager({
        variables: {
            areaManagerId: id || '',
        },
    });

    //memo's
    const rows = useMemo(() => {
        if (schedules?.capacitySchedulesByAreaManager) {
            return normalizeRows(schedules?.capacitySchedulesByAreaManager);
        }
        return [];
    }, [schedules?.capacitySchedulesByAreaManager]);

    const handleCreateClick = () => {
        dialogSettings.openDialog(dialogs.createSet, id);
    };

    const handleCreateGroupClick = () => {
        dialogSettings.openDialog(dialogs.createGroup, id);
    };

    const handleDelete = (row: string) => {
        dialogSettings.openDialog(dialogs.deleteSet, row);
    };

    const handleEdit = (row: string) => {
        dialogSettings.openDialog(dialogs.editSet, row);
    };

    const handleGroupEdit = (row: string) => {
        dialogSettings.openDialog(dialogs.editGroup, row);
    };

    const handleActionClick = (row: string, action: string) => {
        switch (action) {
            case t('general.edit'):
                handleEdit(row);
                break;
            case t('general.delete'):
                handleDelete(row);
                break;
            default:
                throw new Error(t('error.general'));
        }
    };

    const handleRowActionClick = (row: string, action: string) => {
        switch (action) {
            case t('general.edit'):
                handleGroupEdit(row);
                break;
            default:
                throw new Error(t('error.general'));
        }
    };

    return (
        <DefaultLayout>
            <React.Fragment>
                <TopWrap>
                    <Typography variant="h1">
                        {t('areas.capacity')} {data?.areaManager.description}
                    </Typography>

                    <ButtonWrapStyled>
                        <ButtonStyled variant="contained" onClick={handleCreateGroupClick}>
                            {t('area-group.add')}
                        </ButtonStyled>
                        <ButtonStyled variant="contained" color='secondary' onClick={handleCreateClick}>
                            {t('areas.newset')}
                        </ButtonStyled>
                    </ButtonWrapStyled>
                </TopWrap>

                <Typography variant="h2">{t('area-group.activeset')}</Typography>
                {rows
                    .filter((item) => item?.startDate < DateTime.now().toISO() && !item?.endDate)
                    .map((row, idx) => (
                        <SetsTable
                            head={head}
                            key={idx}
                            setId={row.id}
                            groupRows={row.areaGroupCapacities}
                            onRowActionClick={handleRowActionClick}
                            manager={row.areaManagerCapacity}
                            endDate={row?.endDate}
                        />
                    ))}

                {rows.filter((item) => item?.startDate > DateTime.now().toISO()).length > 0 && <Typography variant="h2">{t('area-group.futuresets')}</Typography>}
                {rows
                    .filter((item) => item?.startDate > DateTime.now().toISO())
                    .map((row, idx) => (
                        <SetsTable
                            head={head}
                            key={idx}
                            setId={row.id}
                            onActionClick={handleActionClick}
                            actions={actions}
                            groupRows={row.areaGroupCapacities}
                            manager={row.areaManagerCapacity}
                            endDate={row?.endDate}
                        />
                    ))}

                <Typography variant="h2">{t('area-group.expiredsets')}</Typography>

                {rows
                    .filter((item) => item?.startDate < DateTime.now().toISO() && item?.endDate)
                    .map((row, idx) => (
                        <SetsTable
                            head={head}
                            key={idx}
                            setId={row.id}
                            groupRows={row.areaGroupCapacities}
                            manager={row.areaManagerCapacity}
                            endDate={row?.endDate}
                        />
                    ))}
            </React.Fragment>
        </DefaultLayout>
    );
};

export default AreaDetail;

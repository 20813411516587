import i18n from 'locales/i18n';

export const head = [
    i18n.t('general.firstname'),
    i18n.t('general.lastname'),
    i18n.t('general.email'),
];

export const rowFormat = ['firstName', 'lastName', 'email'];

export type Item = {
    email: string;
    firstName: string;
    lastName: string;
    id?: number | string;
};

export type Data = Item[];
export const normalizeRows = (data: Data) => {
    const returnArray = data.map((item: Item) => {
        return {
            email: item.email,
            id: item.id || '',
            firstName: item.firstName,
            lastName: item.lastName,
        };
    });

    return returnArray;
};

export const actions = [i18n.t('general.edit'), i18n.t('general.delete')];

import React, { ReactNode } from 'react';
import { useDialogSettings, useGetDialogName } from 'packages/shared/Context/DialogSettings';
import { Button, DialogTitle, DialogContent, DialogActions, Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
    name: string;
    title: string;
    children: ReactNode;
    maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs';
    acceptName?: string;
    onClick?: () => void;
    onClose?: () => void;
};

const DialogComponent = (props: Props) => {
    const { name, title, children, maxWidth = 'sm', acceptName, onClick, onClose } = props;
    const dialogSettings = useDialogSettings();
    const { t } = useTranslation();
    const dialogName = useGetDialogName();

    //functions
    const handleClose = () => {
        dialogSettings.closeDialog();
        onClose?.();
    };

    const handleClick = () => {
        if (onClick) {
            onClick?.();
        }
    };

    if(dialogName !== name) return <></>

    return (
        <Dialog fullWidth open={dialogName === name} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth={maxWidth}>
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined">
                        {t('general.cancel')}
                    </Button>
                    {onClick && (
                        <Button onClick={handleClick} variant="contained" color="secondary">
                            {acceptName || t('general.ok')}
                        </Button>
                    )}
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

export default DialogComponent;

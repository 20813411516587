import { useReducer } from 'react';
import { useTranslation } from 'react-i18next';

export type FormObject = {
    [key: string]: string | null;
};

const useForm = (requirements: { [key: string]: boolean | null } = {}) => {
    const { t } = useTranslation();
    const reducer = (state: FormObject, data: FormObject) => {
        if (data.reset && data.reset === 'true') return {};

        return {
            ...state,
            ...data,
        };
    };

    const [fields, setFields] = useReducer(reducer, {});
    const [errors, setError] = useReducer(reducer, {});

    const formFunctions = {
        onFieldChange: (data: { key: string; value: string }) => {
            setFields({ [data.key]: data.value });
            setError({ [data.key]: null });
        },
        onSubmit: () => {
            return submitFunction();
        },
        onReset: () => {
            setFields({ reset: 'true' });
            setError({ reset: 'true' });
        },
        fields,
        errors,
    };

    const submitFunction = () => {
        return new Promise<FormObject>((resolve, reject) => {
            const fieldsToValidate = Object.keys(requirements);
            const errorFields = Object.keys(errors);
            let validated = true;

            errorFields.forEach((error) => {
                setError({ [error]: null });
            });

            fieldsToValidate.forEach((field) => {
                if (!fields[field] && requirements[field]) {
                    setError({ [field]: t('validation.fieldRequired') });
                    validated = false;
                }
            });

            if (validated) {
                resolve(fields);
            } else {
                reject(t('validation.validationError'));
            }
        });
    };

    return [formFunctions];
};

export default useForm;

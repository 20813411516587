import React from 'react';
import {Chart as ChartJS, ChartType, registerables, TooltipModel} from 'chart.js';
import {Chart} from 'react-chartjs-2';
import {Typography} from '@mui/material';
import {styled} from '@mui/system';
import ReactDOMServer from "react-dom/server";
import {TOOLTIP_WIDTH} from "./BarChartToolTip";

ChartJS.register(...registerables);

const BarChartRoot = styled('div')({
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
});

const BarChartWrapper = styled('div')({});

const BarChartLegendLeft = styled('div')({
    writingMode: 'vertical-rl',
    textTransform: 'uppercase',
    marginRight: '10px',
});

const BarChartLegendBottom = styled('div')({
    gridColumn: '2',
    justifySelf: 'flex-end',
    textTransform: 'uppercase',
    marginTop: '10px',
});

type Dataset = {
    label: string;
    data: number[];
    backgroundColor?: string;
    stack: string;
};

type Data = {
    labels: string[];
    datasets: Dataset[];
};

type Props = {
    data: Data;
    options?: object;
    legendLeftText?: string;
    legendBottomText?: string;
    tooltipRenderer?: (index: number) => JSX.Element;
};

const BarChart = (props: Props) => {
    const externalTooltipHandler = (context: { chart: { canvas: HTMLCanvasElement }; tooltip: TooltipModel<ChartType> }) => {
        if (!tooltipRenderer) return;

        const {chart, tooltip: tooltipData} = context;
        let tooltipEl = chart.canvas.parentNode?.querySelector('div');

        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.transform = 'translate(-50%, 0)';
            tooltipEl.style.transition = 'all .1s ease';

            const table = document.createElement('table');
            tooltipEl.appendChild(table);
            chart.canvas.parentNode?.appendChild(tooltipEl);
        }

        // Hide if no tooltip
        if (tooltipData.opacity === 0) {
            tooltipEl.style.opacity = "0";
            return;
        }

        if (tooltipData.body) {
            const tableRoot = tooltipEl.querySelector('table');
            if (!tableRoot) return;

            // Remove old children
            while (tableRoot.firstChild) tableRoot.firstChild.remove();

            // Find index using the label, not ideal, but we don't have a choice...
            const index = data.labels.findIndex(label => label === tooltipData.title?.[0]);

            // Insert a React component as tooltip
            tableRoot.insertAdjacentHTML("beforeend", ReactDOMServer.renderToString(tooltipRenderer(index)));
        }

        const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;

        tooltipEl.style.opacity = "1";
        tooltipEl.style.left = positionX + tooltipData.caretX + 'px';
        tooltipEl.style.top = positionY + tooltipData.caretY - (TOOLTIP_WIDTH / 2) + 'px';
    };

    const {
        data,
        tooltipRenderer,
        options = {
            plugins: {
                legend: {
                    display: false,
                },
                ...(tooltipRenderer && {
                    tooltip: {
                        enabled: false,
                        external: externalTooltipHandler
                    }
                })
            },
            responsive: true,
            interaction: {
                mode: 'index' as const,
                intersect: false,
            },
            scales: {
                y: {
                    stacked: false,
                },
            },
        },
        legendLeftText,
        legendBottomText,
    } = props;

    return (
        <BarChartRoot>
            <BarChartLegendLeft>
                <Typography variant="caption">{legendLeftText}</Typography>
            </BarChartLegendLeft>
            <BarChartWrapper>
                <Chart type="bar" options={options} data={data} />
            </BarChartWrapper>
            <BarChartLegendBottom>
                <Typography variant="caption">{legendBottomText}</Typography>
            </BarChartLegendBottom>
        </BarChartRoot>
    );
};

export default BarChart;

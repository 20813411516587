import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import translationNl from './nl.json';

const options = {
    resources: {
        nl: {
            translation: translationNl,
        },
    },
    lng: 'nl',
    fallbackLng: 'nl',
    interpolation: {
        escapeValue: false,
    },
    parseMissingKeyHandler: (key) => {
        if (process.env.DEBUG) console.log(`No translation found for "${key}"`);
        return '';
    },
};

i18n.use(Backend).use(initReactI18next).init(options);

export default i18n;

import React, { useMemo } from 'react';
import DefaultLayout from 'packages/app/DefaultLayout';
import CapacityScheduleTable from './CapacityScheduleTable';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import { useDialogSettings } from 'packages/shared/Context/DialogSettings';
import { styled } from '@mui/system';
import { RowType, dialogs } from 'lib';
import { useAreamanagers } from 'graph';
import { usePagination } from 'hooks';
import { normalizeRows, head } from './tableSettings';
import { useNavigate } from 'react-router-dom';
import { AreaPath, AreaManagerCapacityOverviewPath } from 'components/Router/Router';

const TopWrap = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '45px',
});

const ButtonStyled = styled(Button)({
    marginLeft: '20px',
});

const ButtonWrapStyled = styled('div')({
    display: 'flex',
});

const Dashboard = () => {
    const { t } = useTranslation();
    const dialogSettings = useDialogSettings();
    const pagination = usePagination();
    const history = useNavigate();

    const { data, loading } = useAreamanagers({
        variables: {
            pagination: {
                ...pagination.pagination,
            },
        },
        pollInterval: 5000,
    });

    //memo's
    const rows = useMemo(() => {
        if (data?.areaManagers && data?.areaManagers?.content) {
            return normalizeRows(data.areaManagers.content);
        }
        return [];
    }, [data?.areaManagers]);

    const handleCreateClick = () => {
        dialogSettings.openDialog(dialogs.createArea);
    };

    const handleRowClick = (row: RowType) => {
        history(`${AreaPath}/${row.id}`);
    };

    const handleDayClick = (days: number, rowId: string) => {
        history(`${AreaManagerCapacityOverviewPath}/${days}/${rowId}`);
    };

    return (
        <DefaultLayout>
            <React.Fragment>
                <TopWrap>
                    <Typography variant="h1">{t('general.areas')}</Typography>

                    <ButtonWrapStyled>
                        <ButtonStyled variant="contained" color='secondary' onClick={handleCreateClick}>
                            {t('areas.add')}
                        </ButtonStyled>
                    </ButtonWrapStyled>
                </TopWrap>
                <CapacityScheduleTable loading={loading} head={head} rows={rows} onRowClick={handleRowClick} onDayClick={handleDayClick} />
            </React.Fragment>
        </DefaultLayout>
    );
};

export default Dashboard;

import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { TablePagination } from '@mui/material';
import { FilterType } from 'hooks/usePagination/usePagination';

type Props = {
    query: FilterType;
    count?: number;
    rowsPerPage?: number[];
};

const PaginationComponent = (props: Props) => {
    const { count = 0, query, rowsPerPage = [1, 10, 30, 100] } = props;
    const { t } = useTranslation();
    const { pagination } = query;

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        query.setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        query.setPageSize(parseInt(event.target.value, 10));
    };

    if (!pagination) return <div></div>;
    return (
        <div>
            <TablePagination
                rowsPerPageOptions={rowsPerPage}
                count={count}
                rowsPerPage={pagination.pageSize || 0}
                page={pagination.page || 0}
                component="div"
                labelRowsPerPage={t('pagination.rowsPerPage')}
                SelectProps={{
                    inputProps: {
                        'aria-label': t('pagination.rowsPerPage'),
                    },
                    native: true,
                }}
                labelDisplayedRows={({ from, to, count }) => {
                    return `${from}-${to} ${t('pagination.off')} ${count}`;
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
};

export default PaginationComponent;

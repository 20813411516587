import React, {useMemo, useState} from 'react';
import { useAuthorisations } from 'graph';
import { usePagination } from 'hooks';
import DefaultLayout from 'packages/app/DefaultLayout';
import { head, normalizeRows, rowFormat, actions } from './tableSettings';
import PaginatedTable from 'packages/app/PaginatedTable';
import Loader from 'packages/shared/core/Loader';
import { useDialogSettings } from 'packages/shared/Context/DialogSettings';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import { asEndOfDayISOString, asStartOfDayISOString, dialogs } from 'lib';
import {DateRangePicker} from "packages/app/DatePicker";
import {DateRange} from "@mui/lab/DateRangePicker";
import {DateTime} from "luxon";

const DEFAULT_DATE_RANGE_FALLBACK = '30';

const TopWrap = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '30px',
});

export type RowType = {
    id: string | number;
    [propName: string]: string | number | boolean;
};

const Authorisations = () => {
    const pagination = usePagination();
    const dialogSettings = useDialogSettings();
    const { t } = useTranslation();
    const [currentDate] = useState<Date>(new Date());
    const [days] = useState<string>(DEFAULT_DATE_RANGE_FALLBACK);

    const [startDate, setStartDate] = useState<string>(
        asStartOfDayISOString(DateTime.now()
            .minus({ days: Number(days) }))
    );

    const [endDate, setEndDate] = useState<string>(asEndOfDayISOString(DateTime.now()));
    const [dateRange, setDateRange] = useState<DateRange<Date>>([DateTime.now()
        .minus({ days: Number(days) }).toJSDate(), DateTime.now().toJSDate()]);

    const { data, loading } = useAuthorisations({
        variables: {
            pagination: {
                ...pagination.pagination,
            },
            filter: {
                fromDate: startDate,
                toDate: endDate
            }
        },
    });

    const handleCancelAuthorisation = (row: RowType) => {
        dialogSettings.openDialog(dialogs.cancelAuthorisations, row.id.toString());
    };

    const handleActionClick = (row: RowType, action: string) => {
        switch (action) {
            case t('authorisations.cancel'):
                handleCancelAuthorisation(row);
                break;
            default:
                throw new Error(t('error.general'));
        }
    };

    const rows = useMemo(() => {
        if (data?.authorisations && data?.authorisations.content) {
            return normalizeRows(data.authorisations.content);
        }
        return [];
    }, [data?.authorisations]);

    const handleDateRangeChange = (value: DateRange<Date>) => {
        const startDate = value[0];
        const endDate = value[1];

        if (startDate && endDate) {
            setDateRange(value);
            setStartDate(asStartOfDayISOString(DateTime.fromJSDate(startDate)));
            setEndDate(asEndOfDayISOString(DateTime.fromJSDate(endDate)));
        }
    };

    return (
        <DefaultLayout>
            <React.Fragment>
                {loading ? (
                    <Loader />
                ) : (
                    <React.Fragment>
                        <TopWrap>
                            <Typography variant="h1">{t('general.authorisations')}</Typography>
                            <DateRangePicker
                                defaultCalendarMonth={DateTime.local().minus({months: 2}).toJSDate()}
                                disableFuture={true}
                                onChange={handleDateRangeChange}
                                daysSelected={days}
                                value={dateRange}
                            />
                        </TopWrap>
                        <PaginatedTable
                            head={head}
                            rows={rows}
                            rowFormat={rowFormat}
                            count={data?.authorisations.content.length || 0}
                            filter={pagination}
                            onActionClick={handleActionClick}
                            actions={actions}
                        />
                    </React.Fragment>
                )}
            </React.Fragment>
        </DefaultLayout>
    );
};

export default Authorisations;
//fonts
const defaultFont = "'Rubik', sans-serif";

//primary colors
const primaryColor = '#010032';
const primaryColorLight = '#DBE9F9';
const successColor = '#34C279';
const errorColor = '#ED4337';
const textColor = '#4D4C6F';

//Element background colors (menu and toolbar)
const backgroundVariantsMain = '#424B5A';
const backgroundVariantsMedium = '#AFD1F9';
const backgroundVariantsLight = '#ECF1F3';

//general background
const defaultBackgroundColor = '#D9D9E1';
const darkBackgroundColor = '#43474A';
const lightBackgroundColor = '#FFFFFF';

//theme colors
const primaryThemeColor = '#010032';
const secondaryThemeColor = '#CC3202';
const tertiaryThemeColor = '#D9D9E1';
const extraColor = '#821f00';
const greyedOut = '#74748B';
const white = '#FFFFFF';
const opaqueWhite = 'rgb(217, 217, 225, 0.7)';

//border colors
const defaultBorderHoverColor = 'rgba(36,36,69,0.3)';

export const theme = {
    typography: {
        fontFamily: [
            defaultFont,
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        h1: {
            fontSize: '32px',
            lineHeight: '40px',
            color: primaryColor,
            fontWeight: '500',
        },
        h2: {
            fontSize: '26px',
            lineHeight: '36px',
            color: primaryColor,
            fontWeight: '500',
        },
        h3: {
            fontSize: '20px',
            lineHeight: '30px',
            color: primaryColor,
            fontWeight: '500',
        },
        h4: {
            fontSize: '18px',
            lineHeight: '28px',
            color: primaryColor,
            fontWeight: '500',
        },
        h5: {
            fontSize: '18px',
            lineHeight: '30px',
        },
        h6: {
            fontSize: '15px',
            lineHeight: '20px',
        },
        body1: {
            fontSize: '16px',
            lineHeight: '32px',
            color: textColor,
        },
        body2: {
            fontSize: '18px',
            lineheight: '28px',
            color: textColor,
        },
        subtitle1: {
            color: primaryColor,
        },
        caption: {
            fontSize: '14px',
            lineHeight: '20px',
            letterSpacing: '0.25px',
        },
    },
    palette: {
        primary: {
            main: primaryColor,
            light: primaryColorLight,
        },
        secondary: {
          main: secondaryThemeColor,
        },
        success: {
            main: successColor,
            light: '#fff',
        },
        error: {
            main: errorColor,
        },
        backgroundVariants: {
            main: backgroundVariantsMain,
            light: backgroundVariantsLight,
        },
        themePalette: {
            primary: primaryThemeColor,
            secondary: secondaryThemeColor,
            tertiary: tertiaryThemeColor,
            border: defaultBackgroundColor,
            borderDark: defaultBorderHoverColor,
            borderHover: defaultBorderHoverColor,
            greyedOut: greyedOut,
            extraColor: extraColor,
            primaryHoverColor: backgroundVariantsMedium,
            text: textColor,
            white: white,
            opaqueWhite: opaqueWhite
        },
        backgroundPalette: {
            primary: defaultBackgroundColor,
            secondary: darkBackgroundColor,
            light: lightBackgroundColor,
        },
    },

    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                    fontWeight: '300',
                    padding: '10px 16px',
                    color: textColor,
                },
                head: {
                    fontSize: '15px',
                    fontWeight: '500',
                    color: primaryThemeColor,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                outlined: {
                    background: 'none',
                    color: primaryColor,
                    border: `1px solid transparent`,
                    backgroundColor: defaultBackgroundColor,

                    '&:hover': {
                        backgroundColor: defaultBackgroundColor,
                        color: primaryColor,
                    },
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: '15px',
                    padding: '60px 60px 40px',
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: '0 0 20px',
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    padding: '0',
                    fontSize: '28px',
                    lineHeight: '40px',
                    color: primaryColor,
                    fontWeight: '500',
                    textAlign: 'center',
                    marginBottom: '10px',
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    justifyContent: 'space-between',
                    padding: '0',
                },
            },
        },
        MuiTablePagination: {
            styleOverrides: {
                selectLabel: {
                    fontSize: '15px',
                    fontWeight: 'normal',
                },
                displayedRows: {
                    fontSize: '15px',
                    fontWeight: 'normal',
                },
            },
        },
    },
};

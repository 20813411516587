import React, { useMemo, useEffect } from 'react';
import Dialog from 'packages/shared/Dialog';
import { useTranslation } from 'react-i18next';
import { useDialogSettings } from 'packages/shared/Context/DialogSettings';
import { useSnackbar } from 'packages/shared/Context/Snackbar';
import { Typography } from '@mui/material';
import { useDeleteProvider } from 'graph';

const DeleteProviderDialog = () => {
    const { t } = useTranslation();
    const dialogSettings = useDialogSettings();

    const data = useMemo(() => {
        return dialogSettings.getData();
    }, [dialogSettings]);

    //snackbars
    const snackbarError = useSnackbar({
        message: t('error.general'),
        severity: 'error',
    });
    const snackbarSucces = useSnackbar({
        message: t('provider.deletedProvider'),
        severity: 'success',
    });

    //mutations
    const [mutate, mutationData] = useDeleteProvider({
        refetchQueries: ['providers'],
    });

    const handleClick = () => {
        mutate({
            variables: {
                id: data,
            },
        });
    };

    //use effect
    useEffect(() => {
        if (!mutationData.loading) {
            if (mutationData.error) {
                snackbarError.onSetAlert();
                dialogSettings.closeDialog();
            }
            if (
                mutationData.data &&
                mutationData.data.deleteProvider &&
                mutationData.data.deleteProvider === true
            ) {
                snackbarSucces.onSetAlert({
                    message: t('provider.deletedProvider'),
                });
                dialogSettings.closeDialog();
            }
        }
        // eslint-disable-next-line
    }, [mutationData.data, mutationData.error, mutationData.loading]);

    return (
        <Dialog
            acceptName={t('general.confirm')}
            name="deleteProviderDialog"
            title={t('provider.deleteProviderTitle')}
            onClick={handleClick}
        >
            <Typography variant="h4" gutterBottom>
                {t('general.warning')}
            </Typography>
            <Typography variant="body1">
                {t('provider.deleteProviderDialogContent')}
            </Typography>
        </Dialog>
    );
};

export default DeleteProviderDialog;

import { FormObject } from 'hooks/useForm/useForm';
import { DateTime } from 'luxon';

export type RowType = {
    id: string | number;
    [propName: string]: string | number | boolean;
};

export const toIsoString = (date: Date, includeOffset?: boolean) => {
    return DateTime.fromJSDate(date).toISO({ includeOffset: includeOffset });
}

export const toIsoDate = (date: Date) => {
    return DateTime.fromJSDate(date).toISODate();
}

export const toLocalDate = (dateString: string, format = 'dd-MM-yyyy, HH:mm') => {
    return toLocalDateString(toLocalDateObj(dateString), format);
};

export const toJSLocalDateString = (date: Date, format = 'dd-MM-yyyy, HH:mm') => {
    return toLocalDateString(DateTime.fromJSDate(date), format);
};

export const toLocalDateString = (date: DateTime, format = 'dd-MM-yyyy, HH:mm') => {
    return date.toFormat(format);
};

export const toLocalDateObj = (dateString: string) => {
    return DateTime.fromISO(dateString, {
        setZone: true,
        zone: 'utc',
    })
        .setZone('local')
        .setLocale('nl');
};

export const asEndOfDayISOString = (date: DateTime) => {
    return date.toISODate() + 'T23:59';
}

export const asStartOfDayISOString = (date: DateTime) => {
    return date.toISODate() + 'T00:00';
}

export const dialogs = {
    createSet: 'createSetDialog',
    createArea: 'createAreaDialog',
    deleteProvider: 'deleteProviderDialog',
    createProvider: 'createProviderDialog',
    deleteUser: 'deleteUserDialog',
    editUser: 'editUserDialog',
    createUser: 'createUserDialog',
    createGroup: 'createGroupDialog',
    editGroup: 'editGroupDialog',
    editSet: 'editSet',
    deleteSet: 'deleteSet',
    cancelAuthorisations: 'cancelAuthorisationDialog',
};

export const normalizeNestedFormData = (result: FormObject) => {
    let obj: any = {};

    for (const [key, value] of Object.entries(result)) {
        const splitted = key.split('.');

        if(splitted.length === 2) {
            const objName = splitted[0];
            const propertyName = splitted[1];

            if(objName.includes('[')) {
                const splittedObjName = objName.split('[');
                const arrayName = splittedObjName[0];
                const index = splittedObjName[1].split(']')[0];

                if(!obj[arrayName]) {
                    obj[arrayName] = [];
                }

                if(!obj[arrayName][index]) {
                    obj[arrayName][index] = {};
                }

                obj[arrayName][index][propertyName] = value;
            } else {
                if(!obj.hasOwnProperty(objName)) {
                    obj[objName] = {};
                }
                obj[objName][propertyName] = value;
            }
        } else if(splitted.length === 1) {
            obj[splitted[0]] = value;
        }
    }

    return obj;
}

import React from 'react';
import Dialog from 'packages/shared/Dialog';
import { useTranslation } from 'react-i18next';
import { useForm } from 'hooks';
import { useDialogSettings } from 'packages/shared/Context/DialogSettings';
import { useSnackbar } from 'packages/shared/Context/Snackbar';
import AddGroupForm from 'packages/app/forms/AddGroupForm';
import { useCreateAreaGroup } from 'graph';
import { dialogs } from 'lib';
import { Option } from 'packages/shared/core/MultiSelectField';

const CreateGroupDialog = () => {
    const { t } = useTranslation();
    const [form] = useForm({
        description: true,
        areas: true,
    });
    const dialogSettings = useDialogSettings();
    const areaManagerId = dialogSettings.getData();

    //mutation
    const [mutate] = useCreateAreaGroup({
        refetchQueries: ['areaManager', 'areas'],
    });

    //snackbars
    const snackbarError = useSnackbar({
        message: t('error.general'),
        severity: 'error',
    });
    const snackbarSucces = useSnackbar({
        message: t('area-group.success'),
        severity: 'success',
    });

    //functions
    const handleClick = () => {
        form.onSubmit()
            .then((result: any) => {
                const areaIds = result.areas.map((area: Option) => area.value);

                mutate({
                    variables: {
                        input: {
                            ...result,
                            areas: areaIds,
                            areaManagerId: areaManagerId,
                        },
                    },
                })
                    .then((result) => {
                        if (result?.data?.createAreaGroup?.id) {
                            snackbarSucces.onSetAlert();
                            dialogSettings.closeDialog();

                            window.location.reload();
                        }
                    })
                    .catch(() => {
                        snackbarError.onSetAlert();
                    });
            })
            .catch((e) => {
                snackbarError.onSetAlert();
            });
    };

    const handleClose = () => {
        form.onReset();
    };

    return (
        <Dialog
            title={t('area-group.add')}
            name={dialogs.createGroup}
            onClick={handleClick}
            onClose={handleClose}
            acceptName={t('area-group.add')}
        >
            <AddGroupForm form={form} areaManagerId={areaManagerId} />
        </Dialog>
    );
};

export default CreateGroupDialog;

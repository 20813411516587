import React, { useState, useMemo } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

type ActionCallback = (action: string) => void;

type Props = {
    actions: string[];
    fontSize?: 'medium' | 'small' | 'inherit' | 'large' | undefined;
    onClick: ActionCallback;
};

const ActionMenu = (props: Props) => {
    const { actions, fontSize = 'medium' } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = useMemo(() => {
        return Boolean(anchorEl);
    }, [anchorEl]);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (action?: string) => () => {
        if (props.onClick && action) props.onClick(action);
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton aria-label="action" aria-controls="menu" aria-haspopup="true" onClick={handleClick}>
                <MoreHorizIcon fontSize={fontSize} />
            </IconButton>
            <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose()}>
                {actions.map((action) => (
                    <MenuItem key={action} selected={false} onClick={handleClose(action)}>
                        {action}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default ActionMenu;

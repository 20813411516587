import i18n from 'locales/i18n';
import { CapacityScheduleDto } from 'graph';

export const head = [i18n.t('areas.group'), i18n.t('areas.areacodes'), i18n.t('areas.percentage'), i18n.t('areas.capacity'), ''];

export const normalizeRows = (data: CapacityScheduleDto[]) => {
    const returnArray = data.map((item: CapacityScheduleDto) => {
        return {
            ...item,
        };
    });

    return returnArray;
};

export const actions = [i18n.t('general.edit'), i18n.t('general.delete')];

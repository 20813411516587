import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
export type AreaGroupsQueryVariables = Exact<{
  pagination: PaginationInput;
  filter?: InputMaybe<AreaGroupFilterInput>;
}>;


export type AreaGroupsQuery = { __typename?: 'Query', areaGroups: { __typename?: 'AreaGroupPage', content: Array<{ __typename?: 'AreaGroupDto', id?: any | null, description: string, areas?: Array<{ __typename?: 'AreaDto', areaId: string, id?: any | null, description: string }> | null }> } };

export type UpdateAreaGroupVariables = Exact<{
  input: UpdateAreaGroupInput;
}>;


export type UpdateAreaGroup = { __typename?: 'Mutation', updateAreaGroup: { __typename?: 'AreaGroupDto', id?: any | null, description: string } };

export type CreateAreaGroupVariables = Exact<{
  input: CreateAreaGroupInput;
}>;


export type CreateAreaGroup = { __typename?: 'Mutation', createAreaGroup: { __typename?: 'AreaGroupDto', id?: any | null, description: string } };

export type AreaGroupQueryVariables = Exact<{
  id: Scalars['Long'];
}>;


export type AreaGroupQuery = { __typename?: 'Query', areaGroup: { __typename?: 'AreaGroupDto', id?: any | null, description: string, areaManager?: string | null, areas?: Array<{ __typename?: 'AreaDto', areaId: string, id?: any | null, description: string }> | null } };

export type AreaManagerCapacityFragment = { __typename?: 'AreaManagerCapacityDto', capacity: number, maxProviderPercentage: any, startDate: any, usageId: string, currentCapacity?: number | null, providerCapacities: Array<{ __typename?: 'AreaManagerProviderCapacityDto', limit: number, providerId: string }> };

export type AreamanagersVariables = Exact<{
  pagination: PaginationInput;
  filter?: InputMaybe<AreaManagerFilterInput>;
}>;


export type Areamanagers = { __typename?: 'Query', areaManagers: { __typename?: 'AreaManagerPage', totalElements: any, totalPages: number, content: Array<{ __typename?: 'AreaManagerDto', description: string, id?: string | null, occupation?: any | null, capacity?: any | null, overflowCount1Day?: any | null, overflowCount7Days?: any | null, overflowCount28Days?: any | null, areaGroups: Array<{ __typename?: 'AreaGroupDto', id?: any | null, description: string, areas?: Array<{ __typename?: 'AreaDto', areaId: string, areaManagerId: string, description: string, id?: any | null }> | null }> }> } };

export type AreaManagerVariables = Exact<{
  id: Scalars['String'];
}>;


export type AreaManager = { __typename?: 'Query', areaManager: { __typename?: 'AreaManagerDto', description: string, id?: string | null, occupation?: any | null, capacity?: any | null, overflowCount1Day?: any | null, overflowCount7Days?: any | null, overflowCount28Days?: any | null, areaGroups: Array<{ __typename?: 'AreaGroupDto', description: string, id?: any | null, areas?: Array<{ __typename?: 'AreaDto', areaId: string, areaManagerId: string, description: string, id?: any | null }> | null }> } };

export type CreateAreaManagerVariables = Exact<{
  input: CreateAreaManagerInput;
}>;


export type CreateAreaManager = { __typename?: 'Mutation', createAreaManager: { __typename?: 'AreaManagerDto', description: string, id?: string | null } };

export type UpdateAreaManagerVariables = Exact<{
  input: UpdateAreaManagerInput;
}>;


export type UpdateAreaManager = { __typename?: 'Mutation', updateAreaManager: { __typename?: 'AreaManagerDto', id?: string | null, description: string } };

export type DeleteAreaManagerVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteAreaManager = { __typename?: 'Mutation', deleteAreaManager: boolean };

export type AreaManagerCapacityChartVariables = Exact<{
  areaManagerId: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  areaGroupId?: InputMaybe<Scalars['Long']>;
}>;


export type AreaManagerCapacityChart = { __typename?: 'Query', areaManagerCapacityChart: Array<{ __typename?: 'AreaManagerChartDto', label: string, maxUsage: any, maxCapacity: any, minUsage: any, overflowCount: any, overflows?: Array<{ __typename?: 'OverflowDto', count: any, label: string }> | null }> };

export type AreasVariables = Exact<{
  pagination: PaginationInput;
  filter?: InputMaybe<AreaFilterInput>;
}>;


export type Areas = { __typename?: 'Query', areas: { __typename?: 'AreaPage', content: Array<{ __typename?: 'AreaDto', areaId: string, areaManagerId: string, description: string, id?: any | null }> } };

export type CreateAreaVariables = Exact<{
  input: CreateAreaInput;
}>;


export type CreateArea = { __typename?: 'Mutation', createArea: { __typename?: 'AreaDto', areaId: string } };

export type AuthorisationsVariables = Exact<{
  pagination: PaginationInput;
  filter: AuthorisationFilterInput;
}>;


export type Authorisations = { __typename?: 'Query', authorisations: { __typename?: 'AuthorisationPage', totalPages: number, content: Array<{ __typename?: 'AuthorisationDto', paymentAuthorisationId: string, providerId: string, vehicleId?: string | null, startDate: any, endDate?: any | null, areaId: string }> } };

export type CancelAuthorisationVariables = Exact<{
  input: CancelAuthorisationInput;
}>;


export type CancelAuthorisation = { __typename?: 'Mutation', cancelAuthorisation: boolean };

export type CapacitySchedulesVariables = Exact<{
  pagination: PaginationInput;
  filter?: InputMaybe<CapacityScheduleFilterInput>;
}>;


export type CapacitySchedules = { __typename?: 'Query', capacitySchedules: { __typename?: 'CapacitySchedulePage', totalElements: any, totalPages: number, content: Array<{ __typename?: 'CapacityScheduleDto', areaManagerId: string, startDate: any, areaManagerCapacity: { __typename?: 'AreaManagerCapacityDto', capacity: number, maxProviderPercentage: any, startDate: any, usageId: string, currentCapacity?: number | null, providerCapacities: Array<{ __typename?: 'AreaManagerProviderCapacityDto', limit: number, providerId: string }> }, areaGroupCapacities: Array<{ __typename?: 'AreaGroupCapacityDto', areaGroupId: any, capacity: number, maxProviderPercentage: any, startDate: any, usageId: string, currentCapacity?: number | null }> }> } };

export type CapacityScheduleVariables = Exact<{
  id: Scalars['Long'];
}>;


export type CapacitySchedule = { __typename?: 'Query', capacitySchedule: { __typename?: 'CapacityScheduleDto', areaManagerId: string, startDate: any, areaManagerCapacity: { __typename?: 'AreaManagerCapacityDto', capacity: number, maxProviderPercentage: any, startDate: any, usageId: string, currentCapacity?: number | null, providerCapacities: Array<{ __typename?: 'AreaManagerProviderCapacityDto', limit: number, providerId: string }> }, areaGroupCapacities: Array<{ __typename?: 'AreaGroupCapacityDto', areaGroupId: any, capacity: number, maxProviderPercentage: any, startDate: any, usageId: string, currentCapacity?: number | null, areaGroupDescription: string }> } };

export type CapacitySchedulesByAreaManagerVariables = Exact<{
  areaManagerId: Scalars['String'];
}>;


export type CapacitySchedulesByAreaManager = { __typename?: 'Query', capacitySchedulesByAreaManager: Array<{ __typename?: 'CapacityScheduleDto', areaManagerId: string, startDate: any, endDate?: any | null, id: any, areaManagerCapacity: { __typename?: 'AreaManagerCapacityDto', capacity: number, maxProviderPercentage: any, startDate: any, usageId: string, currentCapacity?: number | null, providerCapacities: Array<{ __typename?: 'AreaManagerProviderCapacityDto', limit: number, providerId: string }> }, areaGroupCapacities: Array<{ __typename?: 'AreaGroupCapacityDto', areaGroupDescription: string, areaGroupId: any, areaIds: Array<string>, capacity: number, currentCapacity?: number | null, maxProviderPercentage: any, startDate: any, endDate?: any | null, usageId: string }> }> };

export type UpdateCapacityScheduleVariables = Exact<{
  input: UpdateCapacityScheduleInput;
}>;


export type UpdateCapacitySchedule = { __typename?: 'Mutation', updateCapacitySchedule: { __typename?: 'CapacityScheduleDto', areaManagerId: string } };

export type CreateCapacityScheduleVariables = Exact<{
  input: CreateCapacityScheduleInput;
}>;


export type CreateCapacitySchedule = { __typename?: 'Mutation', createCapacitySchedule: { __typename?: 'CapacityScheduleDto', areaManagerId: string } };

export type DeleteCapacityScheduleVariables = Exact<{
  id: Scalars['Long'];
}>;


export type DeleteCapacitySchedule = { __typename?: 'Mutation', deleteCapacitySchedule: boolean };

export type MessagesVariables = Exact<{
  pagination: PaginationInput;
  filter?: InputMaybe<MessageFilterInput>;
}>;


export type Messages = { __typename?: 'Query', messages: { __typename?: 'MessagePage', totalPages: number, content: Array<{ __typename?: 'MessageDto', date: any, request: string, response: string, id?: any | null }> } };

export type OverflowsVariables = Exact<{
  overflowFilter?: InputMaybe<OverflowFilterInput>;
}>;


export type Overflows = { __typename?: 'Query', overflows: Array<{ __typename?: 'OverflowGroupDto', areaGroup: { __typename?: 'AreaGroupDto', id?: any | null, description: string }, overflows: Array<{ __typename?: 'OverflowDto', count: any, label: string }> }> };

export type ProvidersVariables = Exact<{
  pagination: PaginationInput;
  query?: InputMaybe<Scalars['String']>;
}>;


export type Providers = { __typename?: 'Query', providers: { __typename?: 'ProviderPage', totalPages: number, totalElements: any, content: Array<{ __typename?: 'ProviderDto', description: string, id?: any | null, providerId: string }> } };

export type DeleteProviderVariables = Exact<{
  id: Scalars['Long'];
}>;


export type DeleteProvider = { __typename?: 'Mutation', deleteProvider: boolean };

export type CreateProviderVariables = Exact<{
  input: CreateProviderInput;
}>;


export type CreateProvider = { __typename?: 'Mutation', createProvider: { __typename?: 'ProviderDto', description: string, id?: any | null } };

export type UsersVariables = Exact<{
  pagination: PaginationInput;
  query?: InputMaybe<Scalars['String']>;
}>;


export type Users = { __typename?: 'Query', users: { __typename?: 'UserPage', totalPages: number, content: Array<{ __typename?: 'UserDto', email: string, firstName: string, lastName: string, id?: any | null, role?: { __typename?: 'RoleDto', name: string, id?: any | null } | null }> } };

export type UserVariables = Exact<{
  id: Scalars['Long'];
}>;


export type User = { __typename?: 'Query', user: { __typename?: 'UserDto', email: string, firstName: string, lastName: string } };

export type CreateUserVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUser = { __typename?: 'Mutation', createUser: { __typename?: 'UserDto', lastName: string, firstName: string, email: string, id?: any | null } };

export type UpdateUserVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateUser = { __typename?: 'Mutation', updateUser: { __typename?: 'UserDto', lastName: string, firstName: string, email: string, id?: any | null } };

export type DeleteUserVariables = Exact<{
  id: Scalars['Long'];
}>;


export type DeleteUser = { __typename?: 'Mutation', deleteUser: boolean };

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A type representing a DateTime */
  DateTime: any;
  /** Long type */
  Long: any;
  /** A type representing a Percentage */
  Percentage: any;
};

export type AbstractDto = {
  id?: Maybe<Scalars['Long']>;
};

export type AreaDto = AbstractDto & {
  __typename?: 'AreaDto';
  areaId: Scalars['String'];
  areaManagerId: Scalars['String'];
  description: Scalars['String'];
  id?: Maybe<Scalars['Long']>;
};

export type AreaFilterInput = {
  areaManagerId?: InputMaybe<Scalars['String']>;
  onlyNotLinked?: InputMaybe<Scalars['Boolean']>;
};

export type AreaGroupCapacityDto = {
  __typename?: 'AreaGroupCapacityDto';
  areaGroupDescription: Scalars['String'];
  areaGroupId: Scalars['Long'];
  areaIds: Array<Scalars['String']>;
  capacity: Scalars['Int'];
  currentCapacity?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['DateTime']>;
  maxProviderPercentage: Scalars['Percentage'];
  startDate: Scalars['DateTime'];
  usageId: Scalars['String'];
};

export type AreaGroupCapacityInput = {
  areaGroupId: Scalars['Long'];
  capacity: Scalars['Int'];
  maxProviderPercentage: Scalars['Percentage'];
  usageId: Scalars['String'];
};

export type AreaGroupDto = AbstractDto & {
  __typename?: 'AreaGroupDto';
  areaManager?: Maybe<Scalars['String']>;
  areas?: Maybe<Array<AreaDto>>;
  description: Scalars['String'];
  id?: Maybe<Scalars['Long']>;
};

export type AreaGroupFilterInput = {
  areaManagerId?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
};

export type AreaGroupPage = {
  __typename?: 'AreaGroupPage';
  content: Array<AreaGroupDto>;
  totalElements: Scalars['Long'];
  totalPages: Scalars['Int'];
};

export type AreaManagerCapacityDto = {
  __typename?: 'AreaManagerCapacityDto';
  capacity: Scalars['Int'];
  currentCapacity?: Maybe<Scalars['Int']>;
  maxProviderPercentage: Scalars['Percentage'];
  providerCapacities: Array<AreaManagerProviderCapacityDto>;
  startDate: Scalars['DateTime'];
  usageId: Scalars['String'];
};

export type AreaManagerCapacityInput = {
  capacity: Scalars['Int'];
  maxProviderPercentage: Scalars['Percentage'];
  providerCapacities: Array<AreaManagerProviderCapacityInput>;
  usageId: Scalars['String'];
};

export type AreaManagerChartDto = {
  __typename?: 'AreaManagerChartDto';
  label: Scalars['String'];
  maxCapacity: Scalars['Long'];
  maxUsage: Scalars['Long'];
  minUsage: Scalars['Long'];
  overflowCount: Scalars['Long'];
  overflows?: Maybe<Array<OverflowDto>>;
};

export type AreaManagerDto = {
  __typename?: 'AreaManagerDto';
  areaGroups: Array<AreaGroupDto>;
  capacity?: Maybe<Scalars['Long']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  occupation?: Maybe<Scalars['Long']>;
  overflowCount1Day?: Maybe<Scalars['Long']>;
  overflowCount7Days?: Maybe<Scalars['Long']>;
  overflowCount28Days?: Maybe<Scalars['Long']>;
};

export type AreaManagerFilterInput = {
  search?: InputMaybe<Scalars['String']>;
};

export type AreaManagerPage = {
  __typename?: 'AreaManagerPage';
  content: Array<AreaManagerDto>;
  totalElements: Scalars['Long'];
  totalPages: Scalars['Int'];
};

export type AreaManagerProviderCapacityDto = {
  __typename?: 'AreaManagerProviderCapacityDto';
  limit: Scalars['Int'];
  providerId: Scalars['String'];
};

export type AreaManagerProviderCapacityInput = {
  limit: Scalars['Int'];
  providerId: Scalars['String'];
};

export type AreaPage = {
  __typename?: 'AreaPage';
  content: Array<AreaDto>;
  totalElements: Scalars['Long'];
  totalPages: Scalars['Int'];
};

export type AuthorisationDto = {
  __typename?: 'AuthorisationDto';
  areaId: Scalars['String'];
  endDate?: Maybe<Scalars['DateTime']>;
  paymentAuthorisationId: Scalars['String'];
  providerId: Scalars['String'];
  startDate: Scalars['DateTime'];
  vehicleId?: Maybe<Scalars['String']>;
};

export type AuthorisationFilterInput = {
  areaManagerId?: InputMaybe<Scalars['String']>;
  fromDate?: InputMaybe<Scalars['DateTime']>;
  toDate?: InputMaybe<Scalars['DateTime']>;
  withoutEndDate?: InputMaybe<Scalars['Boolean']>;
};

export type AuthorisationPage = {
  __typename?: 'AuthorisationPage';
  content: Array<AuthorisationDto>;
  totalElements: Scalars['Long'];
  totalPages: Scalars['Int'];
};

export type CancelAuthorisationInput = {
  endDate: Scalars['DateTime'];
  paymentAuthorisationId: Scalars['String'];
};

export type CapacityScheduleDto = {
  __typename?: 'CapacityScheduleDto';
  areaGroupCapacities: Array<AreaGroupCapacityDto>;
  areaManagerCapacity: AreaManagerCapacityDto;
  areaManagerId: Scalars['String'];
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['Long'];
  startDate: Scalars['DateTime'];
};

export type CapacityScheduleFilterInput = {
  includeCurrentCapacity?: InputMaybe<Scalars['Boolean']>;
  onlyActiveSchedules?: InputMaybe<Scalars['Boolean']>;
  search?: InputMaybe<Scalars['String']>;
};

export type CapacitySchedulePage = {
  __typename?: 'CapacitySchedulePage';
  content: Array<CapacityScheduleDto>;
  totalElements: Scalars['Long'];
  totalPages: Scalars['Int'];
};

export type CreateAreaGroupInput = {
  areaManagerId?: InputMaybe<Scalars['String']>;
  areas?: InputMaybe<Array<Scalars['Long']>>;
  description: Scalars['String'];
};

export type CreateAreaInput = {
  areaId: Scalars['String'];
  areaManagerId: Scalars['String'];
  description: Scalars['String'];
};

export type CreateAreaManagerInput = {
  description: Scalars['String'];
  id: Scalars['String'];
};

export type CreateCapacityScheduleInput = {
  areaGroupCapacities: Array<AreaGroupCapacityInput>;
  areaManagerCapacity: AreaManagerCapacityInput;
  areaManagerId: Scalars['String'];
  startDate: Scalars['DateTime'];
};

export type CreateProviderInput = {
  description: Scalars['String'];
  providerId: Scalars['String'];
};

export type CreateUserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  role?: InputMaybe<Scalars['Long']>;
};

export type Direction =
  | 'ASC'
  | 'DESC';

export type MessageDto = AbstractDto & {
  __typename?: 'MessageDto';
  date: Scalars['DateTime'];
  id?: Maybe<Scalars['Long']>;
  request: Scalars['String'];
  response: Scalars['String'];
};

export type MessageFilterInput = {
  fromDate?: InputMaybe<Scalars['DateTime']>;
  toDate?: InputMaybe<Scalars['DateTime']>;
};

export type MessagePage = {
  __typename?: 'MessagePage';
  content: Array<MessageDto>;
  totalElements: Scalars['Long'];
  totalPages: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  cancelAuthorisation: Scalars['Boolean'];
  createArea: AreaDto;
  createAreaGroup: AreaGroupDto;
  createAreaManager: AreaManagerDto;
  createCapacitySchedule: CapacityScheduleDto;
  createProvider: ProviderDto;
  createUser: UserDto;
  deleteArea: Scalars['Boolean'];
  deleteAreaGroup: Scalars['Boolean'];
  deleteAreaManager: Scalars['Boolean'];
  deleteCapacitySchedule: Scalars['Boolean'];
  deleteProvider: Scalars['Boolean'];
  deleteUser: Scalars['Boolean'];
  updateArea: AreaDto;
  updateAreaGroup: AreaGroupDto;
  updateAreaManager: AreaManagerDto;
  updateCapacitySchedule: CapacityScheduleDto;
  updateProvider: ProviderDto;
  updateUser: UserDto;
};


export type MutationCancelAuthorisationArgs = {
  input: CancelAuthorisationInput;
};


export type MutationCreateAreaArgs = {
  input: CreateAreaInput;
};


export type MutationCreateAreaGroupArgs = {
  input: CreateAreaGroupInput;
};


export type MutationCreateAreaManagerArgs = {
  input: CreateAreaManagerInput;
};


export type MutationCreateCapacityScheduleArgs = {
  input: CreateCapacityScheduleInput;
};


export type MutationCreateProviderArgs = {
  input: CreateProviderInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationDeleteAreaArgs = {
  id: Scalars['Long'];
};


export type MutationDeleteAreaGroupArgs = {
  id: Scalars['Long'];
};


export type MutationDeleteAreaManagerArgs = {
  id: Scalars['String'];
};


export type MutationDeleteCapacityScheduleArgs = {
  id: Scalars['Long'];
};


export type MutationDeleteProviderArgs = {
  id: Scalars['Long'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['Long'];
};


export type MutationUpdateAreaArgs = {
  input: UpdateAreaInput;
};


export type MutationUpdateAreaGroupArgs = {
  input: UpdateAreaGroupInput;
};


export type MutationUpdateAreaManagerArgs = {
  input: UpdateAreaManagerInput;
};


export type MutationUpdateCapacityScheduleArgs = {
  input: UpdateCapacityScheduleInput;
};


export type MutationUpdateProviderArgs = {
  input: UpdateProviderInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type OverflowDto = {
  __typename?: 'OverflowDto';
  count: Scalars['Long'];
  label: Scalars['String'];
};

export type OverflowFilterInput = {
  areaManagerId?: InputMaybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  groupId?: InputMaybe<Scalars['Long']>;
  startDate: Scalars['DateTime'];
};

export type OverflowGroupDto = {
  __typename?: 'OverflowGroupDto';
  areaGroup: AreaGroupDto;
  overflows: Array<OverflowDto>;
};

export type PaginationInput = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortMap?: InputMaybe<Array<SortPairInput>>;
};

export type ProviderDto = AbstractDto & {
  __typename?: 'ProviderDto';
  description: Scalars['String'];
  id?: Maybe<Scalars['Long']>;
  providerId: Scalars['String'];
};

export type ProviderPage = {
  __typename?: 'ProviderPage';
  content: Array<ProviderDto>;
  totalElements: Scalars['Long'];
  totalPages: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  areaGroup: AreaGroupDto;
  areaGroups: AreaGroupPage;
  areaManager: AreaManagerDto;
  areaManagerCapacityChart: Array<AreaManagerChartDto>;
  areaManagers: AreaManagerPage;
  areas: AreaPage;
  authorisations: AuthorisationPage;
  capacitySchedule: CapacityScheduleDto;
  capacitySchedules: CapacitySchedulePage;
  capacitySchedulesByAreaManager: Array<CapacityScheduleDto>;
  messages: MessagePage;
  overflows: Array<OverflowGroupDto>;
  providers: ProviderPage;
  user: UserDto;
  users: UserPage;
};


export type QueryAreaGroupArgs = {
  id: Scalars['Long'];
};


export type QueryAreaGroupsArgs = {
  filter?: InputMaybe<AreaGroupFilterInput>;
  pagination: PaginationInput;
};


export type QueryAreaManagerArgs = {
  id: Scalars['String'];
};


export type QueryAreaManagerCapacityChartArgs = {
  areaGroupId?: InputMaybe<Scalars['Long']>;
  areaManagerId: Scalars['String'];
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
};


export type QueryAreaManagersArgs = {
  filter?: InputMaybe<AreaManagerFilterInput>;
  pagination: PaginationInput;
};


export type QueryAreasArgs = {
  filter?: InputMaybe<AreaFilterInput>;
  pagination: PaginationInput;
};


export type QueryAuthorisationsArgs = {
  filter?: InputMaybe<AuthorisationFilterInput>;
  pagination: PaginationInput;
};


export type QueryCapacityScheduleArgs = {
  id: Scalars['Long'];
};


export type QueryCapacitySchedulesArgs = {
  filter?: InputMaybe<CapacityScheduleFilterInput>;
  pagination: PaginationInput;
};


export type QueryCapacitySchedulesByAreaManagerArgs = {
  areaManagerId: Scalars['String'];
};


export type QueryMessagesArgs = {
  filter?: InputMaybe<MessageFilterInput>;
  pagination: PaginationInput;
};


export type QueryOverflowsArgs = {
  overflowFilter?: InputMaybe<OverflowFilterInput>;
};


export type QueryProvidersArgs = {
  pagination: PaginationInput;
  query?: InputMaybe<Scalars['String']>;
};


export type QueryUserArgs = {
  id: Scalars['Long'];
};


export type QueryUsersArgs = {
  pagination: PaginationInput;
  query?: InputMaybe<Scalars['String']>;
};

export type RoleDto = AbstractDto & {
  __typename?: 'RoleDto';
  id?: Maybe<Scalars['Long']>;
  name: Scalars['String'];
};

export type SortPairInput = {
  first: Scalars['String'];
  second?: InputMaybe<Direction>;
};

export type UpdateAreaGroupInput = {
  areaManagerId?: InputMaybe<Scalars['String']>;
  areas?: InputMaybe<Array<Scalars['Long']>>;
  description: Scalars['String'];
  id: Scalars['Long'];
};

export type UpdateAreaInput = {
  description: Scalars['String'];
  id: Scalars['Long'];
};

export type UpdateAreaManagerInput = {
  areaGroups?: InputMaybe<Array<Scalars['Long']>>;
  description: Scalars['String'];
  id: Scalars['String'];
};

export type UpdateCapacityScheduleInput = {
  areaGroupCapacities?: InputMaybe<Array<AreaGroupCapacityInput>>;
  areaManagerCapacity?: InputMaybe<AreaManagerCapacityInput>;
  areaManagerId?: InputMaybe<Scalars['String']>;
  id: Scalars['Long'];
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateProviderInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Long'];
};

export type UpdateUserInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['Long'];
  lastName?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['Long']>;
};

export type UserDto = AbstractDto & {
  __typename?: 'UserDto';
  email: Scalars['String'];
  firstName: Scalars['String'];
  id?: Maybe<Scalars['Long']>;
  lastName: Scalars['String'];
  role?: Maybe<RoleDto>;
};

export type UserPage = {
  __typename?: 'UserPage';
  content: Array<UserDto>;
  totalElements: Scalars['Long'];
  totalPages: Scalars['Int'];
};

export const AreaManagerCapacityFragment = gql`
    fragment AreaManagerCapacityFragment on AreaManagerCapacityDto {
  capacity
  maxProviderPercentage
  startDate
  usageId
  currentCapacity
  providerCapacities {
    limit
    providerId
  }
}
    `;
export const AreaGroupsQueryDocument = gql`
    query areaGroupsQuery($pagination: PaginationInput!, $filter: AreaGroupFilterInput) {
  areaGroups(pagination: $pagination, filter: $filter) {
    content {
      id
      description
      areas {
        areaId
        id
        description
      }
    }
  }
}
    `;

/**
 * __useAreaGroupsQuery__
 *
 * To run a query within a React component, call `useAreaGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAreaGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAreaGroupsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAreaGroupsQuery(baseOptions: Apollo.QueryHookOptions<AreaGroupsQuery, AreaGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AreaGroupsQuery, AreaGroupsQueryVariables>(AreaGroupsQueryDocument, options);
      }
export function useAreaGroupsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AreaGroupsQuery, AreaGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AreaGroupsQuery, AreaGroupsQueryVariables>(AreaGroupsQueryDocument, options);
        }
export type AreaGroupsQueryHookResult = ReturnType<typeof useAreaGroupsQuery>;
export type AreaGroupsQueryLazyQueryHookResult = ReturnType<typeof useAreaGroupsQueryLazyQuery>;
export type AreaGroupsQueryQueryResult = Apollo.QueryResult<AreaGroupsQuery, AreaGroupsQueryVariables>;
export const UpdateAreaGroupDocument = gql`
    mutation updateAreaGroup($input: UpdateAreaGroupInput!) {
  updateAreaGroup(input: $input) {
    id
    description
  }
}
    `;
export type UpdateAreaGroupMutationFn = Apollo.MutationFunction<UpdateAreaGroup, UpdateAreaGroupVariables>;

/**
 * __useUpdateAreaGroup__
 *
 * To run a mutation, you first call `useUpdateAreaGroup` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAreaGroup` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAreaGroup, { data, loading, error }] = useUpdateAreaGroup({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAreaGroup(baseOptions?: Apollo.MutationHookOptions<UpdateAreaGroup, UpdateAreaGroupVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAreaGroup, UpdateAreaGroupVariables>(UpdateAreaGroupDocument, options);
      }
export type UpdateAreaGroupHookResult = ReturnType<typeof useUpdateAreaGroup>;
export type UpdateAreaGroupMutationResult = Apollo.MutationResult<UpdateAreaGroup>;
export type UpdateAreaGroupMutationOptions = Apollo.BaseMutationOptions<UpdateAreaGroup, UpdateAreaGroupVariables>;
export const CreateAreaGroupDocument = gql`
    mutation createAreaGroup($input: CreateAreaGroupInput!) {
  createAreaGroup(input: $input) {
    id
    description
  }
}
    `;
export type CreateAreaGroupMutationFn = Apollo.MutationFunction<CreateAreaGroup, CreateAreaGroupVariables>;

/**
 * __useCreateAreaGroup__
 *
 * To run a mutation, you first call `useCreateAreaGroup` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAreaGroup` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAreaGroup, { data, loading, error }] = useCreateAreaGroup({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAreaGroup(baseOptions?: Apollo.MutationHookOptions<CreateAreaGroup, CreateAreaGroupVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAreaGroup, CreateAreaGroupVariables>(CreateAreaGroupDocument, options);
      }
export type CreateAreaGroupHookResult = ReturnType<typeof useCreateAreaGroup>;
export type CreateAreaGroupMutationResult = Apollo.MutationResult<CreateAreaGroup>;
export type CreateAreaGroupMutationOptions = Apollo.BaseMutationOptions<CreateAreaGroup, CreateAreaGroupVariables>;
export const AreaGroupQueryDocument = gql`
    query areaGroupQuery($id: Long!) {
  areaGroup(id: $id) {
    id
    description
    areaManager
    areas {
      areaId
      id
      description
    }
  }
}
    `;

/**
 * __useAreaGroupQuery__
 *
 * To run a query within a React component, call `useAreaGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useAreaGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAreaGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAreaGroupQuery(baseOptions: Apollo.QueryHookOptions<AreaGroupQuery, AreaGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AreaGroupQuery, AreaGroupQueryVariables>(AreaGroupQueryDocument, options);
      }
export function useAreaGroupQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AreaGroupQuery, AreaGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AreaGroupQuery, AreaGroupQueryVariables>(AreaGroupQueryDocument, options);
        }
export type AreaGroupQueryHookResult = ReturnType<typeof useAreaGroupQuery>;
export type AreaGroupQueryLazyQueryHookResult = ReturnType<typeof useAreaGroupQueryLazyQuery>;
export type AreaGroupQueryQueryResult = Apollo.QueryResult<AreaGroupQuery, AreaGroupQueryVariables>;
export const AreamanagersDocument = gql`
    query areamanagers($pagination: PaginationInput!, $filter: AreaManagerFilterInput) {
  areaManagers(pagination: $pagination, filter: $filter) {
    content {
      description
      id
      occupation
      capacity
      overflowCount1Day
      overflowCount7Days
      overflowCount28Days
      areaGroups {
        id
        areas {
          areaId
          areaManagerId
          description
          id
        }
        description
      }
    }
    totalElements
    totalPages
  }
}
    `;

/**
 * __useAreamanagers__
 *
 * To run a query within a React component, call `useAreamanagers` and pass it any options that fit your needs.
 * When your component renders, `useAreamanagers` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAreamanagers({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAreamanagers(baseOptions: Apollo.QueryHookOptions<Areamanagers, AreamanagersVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Areamanagers, AreamanagersVariables>(AreamanagersDocument, options);
      }
export function useAreamanagersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Areamanagers, AreamanagersVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Areamanagers, AreamanagersVariables>(AreamanagersDocument, options);
        }
export type AreamanagersHookResult = ReturnType<typeof useAreamanagers>;
export type AreamanagersLazyQueryHookResult = ReturnType<typeof useAreamanagersLazyQuery>;
export type AreamanagersQueryResult = Apollo.QueryResult<Areamanagers, AreamanagersVariables>;
export const AreaManagerDocument = gql`
    query areaManager($id: String!) {
  areaManager(id: $id) {
    areaGroups {
      description
      id
      areas {
        areaId
        areaManagerId
        description
        id
      }
    }
    description
    id
    occupation
    capacity
    overflowCount1Day
    overflowCount7Days
    overflowCount28Days
  }
}
    `;

/**
 * __useAreaManager__
 *
 * To run a query within a React component, call `useAreaManager` and pass it any options that fit your needs.
 * When your component renders, `useAreaManager` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAreaManager({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAreaManager(baseOptions: Apollo.QueryHookOptions<AreaManager, AreaManagerVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AreaManager, AreaManagerVariables>(AreaManagerDocument, options);
      }
export function useAreaManagerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AreaManager, AreaManagerVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AreaManager, AreaManagerVariables>(AreaManagerDocument, options);
        }
export type AreaManagerHookResult = ReturnType<typeof useAreaManager>;
export type AreaManagerLazyQueryHookResult = ReturnType<typeof useAreaManagerLazyQuery>;
export type AreaManagerQueryResult = Apollo.QueryResult<AreaManager, AreaManagerVariables>;
export const CreateAreaManagerDocument = gql`
    mutation createAreaManager($input: CreateAreaManagerInput!) {
  createAreaManager(input: $input) {
    description
    id
  }
}
    `;
export type CreateAreaManagerMutationFn = Apollo.MutationFunction<CreateAreaManager, CreateAreaManagerVariables>;

/**
 * __useCreateAreaManager__
 *
 * To run a mutation, you first call `useCreateAreaManager` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAreaManager` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAreaManager, { data, loading, error }] = useCreateAreaManager({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAreaManager(baseOptions?: Apollo.MutationHookOptions<CreateAreaManager, CreateAreaManagerVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAreaManager, CreateAreaManagerVariables>(CreateAreaManagerDocument, options);
      }
export type CreateAreaManagerHookResult = ReturnType<typeof useCreateAreaManager>;
export type CreateAreaManagerMutationResult = Apollo.MutationResult<CreateAreaManager>;
export type CreateAreaManagerMutationOptions = Apollo.BaseMutationOptions<CreateAreaManager, CreateAreaManagerVariables>;
export const UpdateAreaManagerDocument = gql`
    mutation updateAreaManager($input: UpdateAreaManagerInput!) {
  updateAreaManager(input: $input) {
    id
    description
  }
}
    `;
export type UpdateAreaManagerMutationFn = Apollo.MutationFunction<UpdateAreaManager, UpdateAreaManagerVariables>;

/**
 * __useUpdateAreaManager__
 *
 * To run a mutation, you first call `useUpdateAreaManager` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAreaManager` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAreaManager, { data, loading, error }] = useUpdateAreaManager({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAreaManager(baseOptions?: Apollo.MutationHookOptions<UpdateAreaManager, UpdateAreaManagerVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAreaManager, UpdateAreaManagerVariables>(UpdateAreaManagerDocument, options);
      }
export type UpdateAreaManagerHookResult = ReturnType<typeof useUpdateAreaManager>;
export type UpdateAreaManagerMutationResult = Apollo.MutationResult<UpdateAreaManager>;
export type UpdateAreaManagerMutationOptions = Apollo.BaseMutationOptions<UpdateAreaManager, UpdateAreaManagerVariables>;
export const DeleteAreaManagerDocument = gql`
    mutation deleteAreaManager($id: String!) {
  deleteAreaManager(id: $id)
}
    `;
export type DeleteAreaManagerMutationFn = Apollo.MutationFunction<DeleteAreaManager, DeleteAreaManagerVariables>;

/**
 * __useDeleteAreaManager__
 *
 * To run a mutation, you first call `useDeleteAreaManager` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAreaManager` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAreaManager, { data, loading, error }] = useDeleteAreaManager({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAreaManager(baseOptions?: Apollo.MutationHookOptions<DeleteAreaManager, DeleteAreaManagerVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAreaManager, DeleteAreaManagerVariables>(DeleteAreaManagerDocument, options);
      }
export type DeleteAreaManagerHookResult = ReturnType<typeof useDeleteAreaManager>;
export type DeleteAreaManagerMutationResult = Apollo.MutationResult<DeleteAreaManager>;
export type DeleteAreaManagerMutationOptions = Apollo.BaseMutationOptions<DeleteAreaManager, DeleteAreaManagerVariables>;
export const AreaManagerCapacityChartDocument = gql`
    query areaManagerCapacityChart($areaManagerId: String!, $startDate: DateTime!, $endDate: DateTime!, $areaGroupId: Long) {
  areaManagerCapacityChart(
    areaManagerId: $areaManagerId
    startDate: $startDate
    endDate: $endDate
    areaGroupId: $areaGroupId
  ) {
    label
    maxUsage
    maxCapacity
    minUsage
    overflowCount
    overflows {
      count
      label
    }
  }
}
    `;

/**
 * __useAreaManagerCapacityChart__
 *
 * To run a query within a React component, call `useAreaManagerCapacityChart` and pass it any options that fit your needs.
 * When your component renders, `useAreaManagerCapacityChart` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAreaManagerCapacityChart({
 *   variables: {
 *      areaManagerId: // value for 'areaManagerId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      areaGroupId: // value for 'areaGroupId'
 *   },
 * });
 */
export function useAreaManagerCapacityChart(baseOptions: Apollo.QueryHookOptions<AreaManagerCapacityChart, AreaManagerCapacityChartVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AreaManagerCapacityChart, AreaManagerCapacityChartVariables>(AreaManagerCapacityChartDocument, options);
      }
export function useAreaManagerCapacityChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AreaManagerCapacityChart, AreaManagerCapacityChartVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AreaManagerCapacityChart, AreaManagerCapacityChartVariables>(AreaManagerCapacityChartDocument, options);
        }
export type AreaManagerCapacityChartHookResult = ReturnType<typeof useAreaManagerCapacityChart>;
export type AreaManagerCapacityChartLazyQueryHookResult = ReturnType<typeof useAreaManagerCapacityChartLazyQuery>;
export type AreaManagerCapacityChartQueryResult = Apollo.QueryResult<AreaManagerCapacityChart, AreaManagerCapacityChartVariables>;
export const AreasDocument = gql`
    query areas($pagination: PaginationInput!, $filter: AreaFilterInput) {
  areas(pagination: $pagination, filter: $filter) {
    content {
      areaId
      areaManagerId
      description
      id
    }
  }
}
    `;

/**
 * __useAreas__
 *
 * To run a query within a React component, call `useAreas` and pass it any options that fit your needs.
 * When your component renders, `useAreas` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAreas({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAreas(baseOptions: Apollo.QueryHookOptions<Areas, AreasVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Areas, AreasVariables>(AreasDocument, options);
      }
export function useAreasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Areas, AreasVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Areas, AreasVariables>(AreasDocument, options);
        }
export type AreasHookResult = ReturnType<typeof useAreas>;
export type AreasLazyQueryHookResult = ReturnType<typeof useAreasLazyQuery>;
export type AreasQueryResult = Apollo.QueryResult<Areas, AreasVariables>;
export const CreateAreaDocument = gql`
    mutation createArea($input: CreateAreaInput!) {
  createArea(input: $input) {
    areaId
  }
}
    `;
export type CreateAreaMutationFn = Apollo.MutationFunction<CreateArea, CreateAreaVariables>;

/**
 * __useCreateArea__
 *
 * To run a mutation, you first call `useCreateArea` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArea` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArea, { data, loading, error }] = useCreateArea({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateArea(baseOptions?: Apollo.MutationHookOptions<CreateArea, CreateAreaVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateArea, CreateAreaVariables>(CreateAreaDocument, options);
      }
export type CreateAreaHookResult = ReturnType<typeof useCreateArea>;
export type CreateAreaMutationResult = Apollo.MutationResult<CreateArea>;
export type CreateAreaMutationOptions = Apollo.BaseMutationOptions<CreateArea, CreateAreaVariables>;
export const AuthorisationsDocument = gql`
    query authorisations($pagination: PaginationInput!, $filter: AuthorisationFilterInput!) {
  authorisations(pagination: $pagination, filter: $filter) {
    content {
      paymentAuthorisationId
      providerId
      vehicleId
      startDate
      endDate
      areaId
      vehicleId
    }
    totalPages
  }
}
    `;

/**
 * __useAuthorisations__
 *
 * To run a query within a React component, call `useAuthorisations` and pass it any options that fit your needs.
 * When your component renders, `useAuthorisations` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthorisations({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAuthorisations(baseOptions: Apollo.QueryHookOptions<Authorisations, AuthorisationsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Authorisations, AuthorisationsVariables>(AuthorisationsDocument, options);
      }
export function useAuthorisationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Authorisations, AuthorisationsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Authorisations, AuthorisationsVariables>(AuthorisationsDocument, options);
        }
export type AuthorisationsHookResult = ReturnType<typeof useAuthorisations>;
export type AuthorisationsLazyQueryHookResult = ReturnType<typeof useAuthorisationsLazyQuery>;
export type AuthorisationsQueryResult = Apollo.QueryResult<Authorisations, AuthorisationsVariables>;
export const CancelAuthorisationDocument = gql`
    mutation cancelAuthorisation($input: CancelAuthorisationInput!) {
  cancelAuthorisation(input: $input)
}
    `;
export type CancelAuthorisationMutationFn = Apollo.MutationFunction<CancelAuthorisation, CancelAuthorisationVariables>;

/**
 * __useCancelAuthorisation__
 *
 * To run a mutation, you first call `useCancelAuthorisation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAuthorisation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAuthorisation, { data, loading, error }] = useCancelAuthorisation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelAuthorisation(baseOptions?: Apollo.MutationHookOptions<CancelAuthorisation, CancelAuthorisationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelAuthorisation, CancelAuthorisationVariables>(CancelAuthorisationDocument, options);
      }
export type CancelAuthorisationHookResult = ReturnType<typeof useCancelAuthorisation>;
export type CancelAuthorisationMutationResult = Apollo.MutationResult<CancelAuthorisation>;
export type CancelAuthorisationMutationOptions = Apollo.BaseMutationOptions<CancelAuthorisation, CancelAuthorisationVariables>;
export const CapacitySchedulesDocument = gql`
    query capacitySchedules($pagination: PaginationInput!, $filter: CapacityScheduleFilterInput) {
  capacitySchedules(pagination: $pagination, filter: $filter) {
    content {
      areaManagerId
      areaManagerCapacity {
        ...AreaManagerCapacityFragment
      }
      areaGroupCapacities {
        areaGroupId
        capacity
        maxProviderPercentage
        startDate
        usageId
        currentCapacity
      }
      areaManagerId
      startDate
    }
    totalElements
    totalPages
  }
}
    ${AreaManagerCapacityFragment}`;

/**
 * __useCapacitySchedules__
 *
 * To run a query within a React component, call `useCapacitySchedules` and pass it any options that fit your needs.
 * When your component renders, `useCapacitySchedules` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCapacitySchedules({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCapacitySchedules(baseOptions: Apollo.QueryHookOptions<CapacitySchedules, CapacitySchedulesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CapacitySchedules, CapacitySchedulesVariables>(CapacitySchedulesDocument, options);
      }
export function useCapacitySchedulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CapacitySchedules, CapacitySchedulesVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CapacitySchedules, CapacitySchedulesVariables>(CapacitySchedulesDocument, options);
        }
export type CapacitySchedulesHookResult = ReturnType<typeof useCapacitySchedules>;
export type CapacitySchedulesLazyQueryHookResult = ReturnType<typeof useCapacitySchedulesLazyQuery>;
export type CapacitySchedulesQueryResult = Apollo.QueryResult<CapacitySchedules, CapacitySchedulesVariables>;
export const CapacityScheduleDocument = gql`
    query capacitySchedule($id: Long!) {
  capacitySchedule(id: $id) {
    areaManagerId
    areaManagerCapacity {
      ...AreaManagerCapacityFragment
    }
    areaGroupCapacities {
      areaGroupId
      capacity
      maxProviderPercentage
      startDate
      usageId
      currentCapacity
      areaGroupDescription
    }
    areaManagerId
    startDate
  }
}
    ${AreaManagerCapacityFragment}`;

/**
 * __useCapacitySchedule__
 *
 * To run a query within a React component, call `useCapacitySchedule` and pass it any options that fit your needs.
 * When your component renders, `useCapacitySchedule` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCapacitySchedule({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCapacitySchedule(baseOptions: Apollo.QueryHookOptions<CapacitySchedule, CapacityScheduleVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CapacitySchedule, CapacityScheduleVariables>(CapacityScheduleDocument, options);
      }
export function useCapacityScheduleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CapacitySchedule, CapacityScheduleVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CapacitySchedule, CapacityScheduleVariables>(CapacityScheduleDocument, options);
        }
export type CapacityScheduleHookResult = ReturnType<typeof useCapacitySchedule>;
export type CapacityScheduleLazyQueryHookResult = ReturnType<typeof useCapacityScheduleLazyQuery>;
export type CapacityScheduleQueryResult = Apollo.QueryResult<CapacitySchedule, CapacityScheduleVariables>;
export const CapacitySchedulesByAreaManagerDocument = gql`
    query capacitySchedulesByAreaManager($areaManagerId: String!) {
  capacitySchedulesByAreaManager(areaManagerId: $areaManagerId) {
    areaManagerCapacity {
      ...AreaManagerCapacityFragment
    }
    areaGroupCapacities {
      areaGroupDescription
      areaGroupId
      areaIds
      capacity
      currentCapacity
      maxProviderPercentage
      startDate
      endDate
      usageId
    }
    areaManagerId
    startDate
    endDate
    id
  }
}
    ${AreaManagerCapacityFragment}`;

/**
 * __useCapacitySchedulesByAreaManager__
 *
 * To run a query within a React component, call `useCapacitySchedulesByAreaManager` and pass it any options that fit your needs.
 * When your component renders, `useCapacitySchedulesByAreaManager` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCapacitySchedulesByAreaManager({
 *   variables: {
 *      areaManagerId: // value for 'areaManagerId'
 *   },
 * });
 */
export function useCapacitySchedulesByAreaManager(baseOptions: Apollo.QueryHookOptions<CapacitySchedulesByAreaManager, CapacitySchedulesByAreaManagerVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CapacitySchedulesByAreaManager, CapacitySchedulesByAreaManagerVariables>(CapacitySchedulesByAreaManagerDocument, options);
      }
export function useCapacitySchedulesByAreaManagerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CapacitySchedulesByAreaManager, CapacitySchedulesByAreaManagerVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CapacitySchedulesByAreaManager, CapacitySchedulesByAreaManagerVariables>(CapacitySchedulesByAreaManagerDocument, options);
        }
export type CapacitySchedulesByAreaManagerHookResult = ReturnType<typeof useCapacitySchedulesByAreaManager>;
export type CapacitySchedulesByAreaManagerLazyQueryHookResult = ReturnType<typeof useCapacitySchedulesByAreaManagerLazyQuery>;
export type CapacitySchedulesByAreaManagerQueryResult = Apollo.QueryResult<CapacitySchedulesByAreaManager, CapacitySchedulesByAreaManagerVariables>;
export const UpdateCapacityScheduleDocument = gql`
    mutation updateCapacitySchedule($input: UpdateCapacityScheduleInput!) {
  updateCapacitySchedule(input: $input) {
    areaManagerId
  }
}
    `;
export type UpdateCapacityScheduleMutationFn = Apollo.MutationFunction<UpdateCapacitySchedule, UpdateCapacityScheduleVariables>;

/**
 * __useUpdateCapacitySchedule__
 *
 * To run a mutation, you first call `useUpdateCapacitySchedule` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCapacitySchedule` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCapacitySchedule, { data, loading, error }] = useUpdateCapacitySchedule({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCapacitySchedule(baseOptions?: Apollo.MutationHookOptions<UpdateCapacitySchedule, UpdateCapacityScheduleVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCapacitySchedule, UpdateCapacityScheduleVariables>(UpdateCapacityScheduleDocument, options);
      }
export type UpdateCapacityScheduleHookResult = ReturnType<typeof useUpdateCapacitySchedule>;
export type UpdateCapacityScheduleMutationResult = Apollo.MutationResult<UpdateCapacitySchedule>;
export type UpdateCapacityScheduleMutationOptions = Apollo.BaseMutationOptions<UpdateCapacitySchedule, UpdateCapacityScheduleVariables>;
export const CreateCapacityScheduleDocument = gql`
    mutation createCapacitySchedule($input: CreateCapacityScheduleInput!) {
  createCapacitySchedule(input: $input) {
    areaManagerId
  }
}
    `;
export type CreateCapacityScheduleMutationFn = Apollo.MutationFunction<CreateCapacitySchedule, CreateCapacityScheduleVariables>;

/**
 * __useCreateCapacitySchedule__
 *
 * To run a mutation, you first call `useCreateCapacitySchedule` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCapacitySchedule` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCapacitySchedule, { data, loading, error }] = useCreateCapacitySchedule({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCapacitySchedule(baseOptions?: Apollo.MutationHookOptions<CreateCapacitySchedule, CreateCapacityScheduleVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCapacitySchedule, CreateCapacityScheduleVariables>(CreateCapacityScheduleDocument, options);
      }
export type CreateCapacityScheduleHookResult = ReturnType<typeof useCreateCapacitySchedule>;
export type CreateCapacityScheduleMutationResult = Apollo.MutationResult<CreateCapacitySchedule>;
export type CreateCapacityScheduleMutationOptions = Apollo.BaseMutationOptions<CreateCapacitySchedule, CreateCapacityScheduleVariables>;
export const DeleteCapacityScheduleDocument = gql`
    mutation deleteCapacitySchedule($id: Long!) {
  deleteCapacitySchedule(id: $id)
}
    `;
export type DeleteCapacityScheduleMutationFn = Apollo.MutationFunction<DeleteCapacitySchedule, DeleteCapacityScheduleVariables>;

/**
 * __useDeleteCapacitySchedule__
 *
 * To run a mutation, you first call `useDeleteCapacitySchedule` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCapacitySchedule` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCapacitySchedule, { data, loading, error }] = useDeleteCapacitySchedule({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCapacitySchedule(baseOptions?: Apollo.MutationHookOptions<DeleteCapacitySchedule, DeleteCapacityScheduleVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCapacitySchedule, DeleteCapacityScheduleVariables>(DeleteCapacityScheduleDocument, options);
      }
export type DeleteCapacityScheduleHookResult = ReturnType<typeof useDeleteCapacitySchedule>;
export type DeleteCapacityScheduleMutationResult = Apollo.MutationResult<DeleteCapacitySchedule>;
export type DeleteCapacityScheduleMutationOptions = Apollo.BaseMutationOptions<DeleteCapacitySchedule, DeleteCapacityScheduleVariables>;
export const MessagesDocument = gql`
    query messages($pagination: PaginationInput!, $filter: MessageFilterInput) {
  messages(pagination: $pagination, filter: $filter) {
    totalPages
    totalPages
    content {
      date
      request
      response
      id
    }
  }
}
    `;

/**
 * __useMessages__
 *
 * To run a query within a React component, call `useMessages` and pass it any options that fit your needs.
 * When your component renders, `useMessages` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessages({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMessages(baseOptions: Apollo.QueryHookOptions<Messages, MessagesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Messages, MessagesVariables>(MessagesDocument, options);
      }
export function useMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Messages, MessagesVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Messages, MessagesVariables>(MessagesDocument, options);
        }
export type MessagesHookResult = ReturnType<typeof useMessages>;
export type MessagesLazyQueryHookResult = ReturnType<typeof useMessagesLazyQuery>;
export type MessagesQueryResult = Apollo.QueryResult<Messages, MessagesVariables>;
export const OverflowsDocument = gql`
    query overflows($overflowFilter: OverflowFilterInput) {
  overflows(overflowFilter: $overflowFilter) {
    areaGroup {
      id
      description
    }
    overflows {
      count
      label
    }
  }
}
    `;

/**
 * __useOverflows__
 *
 * To run a query within a React component, call `useOverflows` and pass it any options that fit your needs.
 * When your component renders, `useOverflows` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOverflows({
 *   variables: {
 *      overflowFilter: // value for 'overflowFilter'
 *   },
 * });
 */
export function useOverflows(baseOptions?: Apollo.QueryHookOptions<Overflows, OverflowsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Overflows, OverflowsVariables>(OverflowsDocument, options);
      }
export function useOverflowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Overflows, OverflowsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Overflows, OverflowsVariables>(OverflowsDocument, options);
        }
export type OverflowsHookResult = ReturnType<typeof useOverflows>;
export type OverflowsLazyQueryHookResult = ReturnType<typeof useOverflowsLazyQuery>;
export type OverflowsQueryResult = Apollo.QueryResult<Overflows, OverflowsVariables>;
export const ProvidersDocument = gql`
    query providers($pagination: PaginationInput!, $query: String) {
  providers(pagination: $pagination, query: $query) {
    totalPages
    totalElements
    content {
      description
      id
      providerId
    }
  }
}
    `;

/**
 * __useProviders__
 *
 * To run a query within a React component, call `useProviders` and pass it any options that fit your needs.
 * When your component renders, `useProviders` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviders({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useProviders(baseOptions: Apollo.QueryHookOptions<Providers, ProvidersVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Providers, ProvidersVariables>(ProvidersDocument, options);
      }
export function useProvidersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Providers, ProvidersVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Providers, ProvidersVariables>(ProvidersDocument, options);
        }
export type ProvidersHookResult = ReturnType<typeof useProviders>;
export type ProvidersLazyQueryHookResult = ReturnType<typeof useProvidersLazyQuery>;
export type ProvidersQueryResult = Apollo.QueryResult<Providers, ProvidersVariables>;
export const DeleteProviderDocument = gql`
    mutation deleteProvider($id: Long!) {
  deleteProvider(id: $id)
}
    `;
export type DeleteProviderMutationFn = Apollo.MutationFunction<DeleteProvider, DeleteProviderVariables>;

/**
 * __useDeleteProvider__
 *
 * To run a mutation, you first call `useDeleteProvider` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProvider` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProvider, { data, loading, error }] = useDeleteProvider({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProvider(baseOptions?: Apollo.MutationHookOptions<DeleteProvider, DeleteProviderVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProvider, DeleteProviderVariables>(DeleteProviderDocument, options);
      }
export type DeleteProviderHookResult = ReturnType<typeof useDeleteProvider>;
export type DeleteProviderMutationResult = Apollo.MutationResult<DeleteProvider>;
export type DeleteProviderMutationOptions = Apollo.BaseMutationOptions<DeleteProvider, DeleteProviderVariables>;
export const CreateProviderDocument = gql`
    mutation createProvider($input: CreateProviderInput!) {
  createProvider(input: $input) {
    description
    id
  }
}
    `;
export type CreateProviderMutationFn = Apollo.MutationFunction<CreateProvider, CreateProviderVariables>;

/**
 * __useCreateProvider__
 *
 * To run a mutation, you first call `useCreateProvider` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProvider` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProvider, { data, loading, error }] = useCreateProvider({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProvider(baseOptions?: Apollo.MutationHookOptions<CreateProvider, CreateProviderVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProvider, CreateProviderVariables>(CreateProviderDocument, options);
      }
export type CreateProviderHookResult = ReturnType<typeof useCreateProvider>;
export type CreateProviderMutationResult = Apollo.MutationResult<CreateProvider>;
export type CreateProviderMutationOptions = Apollo.BaseMutationOptions<CreateProvider, CreateProviderVariables>;
export const UsersDocument = gql`
    query users($pagination: PaginationInput!, $query: String) {
  users(pagination: $pagination, query: $query) {
    totalPages
    content {
      email
      firstName
      lastName
      role {
        name
        id
      }
      id
    }
  }
}
    `;

/**
 * __useUsers__
 *
 * To run a query within a React component, call `useUsers` and pass it any options that fit your needs.
 * When your component renders, `useUsers` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsers({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useUsers(baseOptions: Apollo.QueryHookOptions<Users, UsersVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Users, UsersVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Users, UsersVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Users, UsersVariables>(UsersDocument, options);
        }
export type UsersHookResult = ReturnType<typeof useUsers>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<Users, UsersVariables>;
export const UserDocument = gql`
    query user($id: Long!) {
  user(id: $id) {
    email
    firstName
    lastName
  }
}
    `;

/**
 * __useUser__
 *
 * To run a query within a React component, call `useUser` and pass it any options that fit your needs.
 * When your component renders, `useUser` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUser({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUser(baseOptions: Apollo.QueryHookOptions<User, UserVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<User, UserVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<User, UserVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<User, UserVariables>(UserDocument, options);
        }
export type UserHookResult = ReturnType<typeof useUser>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<User, UserVariables>;
export const CreateUserDocument = gql`
    mutation createUser($input: CreateUserInput!) {
  createUser(input: $input) {
    lastName
    firstName
    email
    id
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUser, CreateUserVariables>;

/**
 * __useCreateUser__
 *
 * To run a mutation, you first call `useCreateUser` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUser` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUser, { data, loading, error }] = useCreateUser({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUser(baseOptions?: Apollo.MutationHookOptions<CreateUser, CreateUserVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUser, CreateUserVariables>(CreateUserDocument, options);
      }
export type CreateUserHookResult = ReturnType<typeof useCreateUser>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUser>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUser, CreateUserVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    lastName
    firstName
    email
    id
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUser, UpdateUserVariables>;

/**
 * __useUpdateUser__
 *
 * To run a mutation, you first call `useUpdateUser` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUser` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUser, { data, loading, error }] = useUpdateUser({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUser(baseOptions?: Apollo.MutationHookOptions<UpdateUser, UpdateUserVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUser, UpdateUserVariables>(UpdateUserDocument, options);
      }
export type UpdateUserHookResult = ReturnType<typeof useUpdateUser>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUser>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUser, UpdateUserVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($id: Long!) {
  deleteUser(id: $id)
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUser, DeleteUserVariables>;

/**
 * __useDeleteUser__
 *
 * To run a mutation, you first call `useDeleteUser` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUser` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUser, { data, loading, error }] = useDeleteUser({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUser(baseOptions?: Apollo.MutationHookOptions<DeleteUser, DeleteUserVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUser, DeleteUserVariables>(DeleteUserDocument, options);
      }
export type DeleteUserHookResult = ReturnType<typeof useDeleteUser>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUser>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUser, DeleteUserVariables>;
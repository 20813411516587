import React from 'react';
import { styled } from '@mui/system';
import classNames from 'classnames';

type Props = {
    occupation: number;
    capacity: number;
    className: string;
};

const PercentageBarRoot = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: 0,

    '.percentagebar-outer': {
        width: '100%',
        height: '50px',
        backgroundColor: theme.palette.themePalette.primary,
        position: 'relative',
        padding: '15px 0',
        display: 'flex',
        justifyContent: 'flex-end',
        borderRadius: '6px',
    },
    '.percentagebar-inner': {
        backgroundColor: theme.palette.themePalette.secondary,
        position: 'absolute',
        height: '50px',
        left: 0,
        top: 0,
        transition: 'width .3s',
        padding: '15px 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingRight: '10px',
        borderBottomLeftRadius: '6px',
        borderTopLeftRadius: '6px',
        fontSize: '14px',

        '&.low-percentage': {
            justifyContent: 'flex-start',
            paddingRight: '0',

            '.percentagebar-current': {
                marginLeft: '10px',
            },
        },
    },
    '.percentagebar-current': {
        color: theme.palette.themePalette.white,
        alignSelf: 'center',
        fontSize: '14px',
    },
    '.percentagebar-capacity': {
        alignSelf: 'center',
        color: theme.palette.themePalette.white,
        marginRight: '10px',
        fontSize: '14px',
    },
}));

const PercentageBar = (props: Props) => {
    const { occupation, capacity, className } = props;
    let percentage = 0;

    if (occupation && capacity) {
        percentage = (occupation / capacity) * 100;
    }

    return (
        <PercentageBarRoot className={className}>
            <div className="percentagebar-outer">
                <div
                    className={classNames(['percentagebar-inner', { 'low-percentage': percentage < 10 }])}
                    style={{
                        width: `${Math.min(percentage, 100) + '%'}`,
                    }}
                >
                    <div className="percentagebar-current">{occupation}</div>
                </div>
                <div className="percentagebar-capacity">{capacity}</div>
            </div>
        </PercentageBarRoot>
    );
};

export default PercentageBar;

import React from 'react';
import { format, RowType } from './PaginatedTable';

export const getProperty = (data: any, format: any) => {
    var parts = format.split('.');

    if (Array.isArray(parts)) {
        var last = parts.pop();

        if (typeof data === 'object') {
            return data[last];
        }
        return ' ';
    }
    return ' ';
};

export const getPropertyFromObject = (data: RowType, format: format) => {
    const property = getProperty(data, format.data);

    switch (format.format) {
        case 'bold':
            return <strong>{property}</strong>;
        default:
            return ' ';
    }
};

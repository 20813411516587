import React, { useState, useMemo } from 'react';
import DefaultLayout from 'packages/app/DefaultLayout';
import { useTranslation } from 'react-i18next';
import {Typography, Link, Breadcrumbs, useTheme} from '@mui/material';
import {styled} from '@mui/system';
import { BarChart } from 'packages/app/Chart';
import { DateRangePicker } from 'packages/app/DatePicker';
import { DateRange } from '@mui/lab/DateRangePicker';
import { useParams } from 'react-router';
import { DateTime } from 'luxon';
import {
    useAreaManagerCapacityChart,
    AreaManagerChartDto,
    useAreaManager,
    useOverflows,
    AreaGroupDto,
    OverflowDto
} from 'graph';
import { SelectField } from 'packages/shared/core';
import OverflowTable from './OverflowTable';
import { head } from './tableSettings';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {FlexTheme} from "../../styles/theme";
import BarChartToolTip from "../../packages/app/Chart/BarChartToolTip";

export type Item = AreaManagerChartDto;

export type OverflowRow = {
    overflowCount: number | undefined;
    overflows: OverflowDto[] | {}[];
};

const TopWrap = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '45px',
});

const BarChartActionBar = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
});

const OverflowTooltipContainer = styled('div')({
    display: 'flex',
    paddingLeft: '56px',
    justifyContent: 'space-between',
    marginTop: '20px',
    position: 'relative',
});

const OverflowTooltipWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const BreadcrumbLink = styled(Link)(({ theme }) => ({
    fontSize: '32px',
    lineHeight: '40px',
    color: theme.palette.themePalette.primary,
    opacity: '0.5',
    fontWeight: '500',
    textDecoration: 'none',

    '&:hover': {
        opacity: '1',
    },
}));

const NextIcon = styled(NavigateNextIcon)(({ theme }) => ({
    color: theme.palette.themePalette.primary,
}));

const OverflowTooltip = styled('div')(({ theme }) => ({
    width: '35px',
    height: '40px',
    backgroundColor: theme.palette.themePalette.secondary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    cursor: 'pointer',
    color: theme.palette.themePalette.white,

    '&:after': {
        content: "''",
        position: 'absolute',
        width: 0,
        height: 0,
        borderWidth: '5px',
        borderStyle: 'solid',
        borderColor: `${theme.palette.themePalette.secondary} transparent transparent transparent`,
        bottom: '-10px',
    },

    '& h3': {
        fontWeight: '400',
        marginBottom: '15px',
    },

    '& p': {
        fontSize: '14px',
    },
}));

const DEFAULT_DATE_RANGE_FALLBACK = '7';

const AreaManagerCapacityOverview = () => {
    const { t } = useTranslation();
    const { id, days = DEFAULT_DATE_RANGE_FALLBACK } = useParams<Record<string, string | undefined>>();

    const theme = useTheme<FlexTheme>();
    const [labels, setLabels] = useState<string[]>([]);
    const [minUsage, setMinUsage] = useState<number[]>([]);
    const [maxCapacity, setMaxCapacity] = useState<number[]>([]);
    const [maxUsage, setMaxUsage] = useState<number[]>([]);
    const [areaGroup, setAreaGroup] = useState<number | string | null>(null);

    const [date] = useState<Date>(new Date());

    const [startDate, setStartDate] = useState<string>(
        DateTime.now()
            .minus({ days: Number(days) })
            .toISODate() + 'T00:00'
    );

    const [endDate, setEndDate] = useState<string>(DateTime.now().toISODate() + 'T00:00');

    const { data: capacityData } = useAreaManagerCapacityChart({
        variables: {
            areaManagerId: id || '',
            startDate: startDate || '',
            endDate: endDate || '',
            areaGroupId: Number(areaGroup) || null,
        },
    });

    const { data: areaManager } = useAreaManager({
        variables: {
            id: id || '',
        },
    });

    const { data: overflowData } = useOverflows({
        variables: {
            overflowFilter: {
                areaManagerId: id || '',
                startDate: startDate || '',
                endDate: endDate || '',
                groupId: Number(areaGroup) || null,
            },
        },
    });

    useMemo(() => {
        if (capacityData?.areaManagerCapacityChart && capacityData?.areaManagerCapacityChart) {
            const barLabels = capacityData.areaManagerCapacityChart.map((item: Item) => {
                return item.label;
            });
            setLabels(barLabels);

            const usage = capacityData.areaManagerCapacityChart.map((item: Item) => {
                return item.minUsage;
            });
            setMinUsage(usage);

            const capacity = capacityData.areaManagerCapacityChart.map((item: Item) => {
                return item.maxCapacity;
            });
            setMaxCapacity(capacity);

            const maxusage = capacityData.areaManagerCapacityChart.map((item: Item) => {
                return item.maxUsage;
            });

            setMaxUsage(maxusage);
        }
    }, [capacityData?.areaManagerCapacityChart]);

    const overflowItems = useMemo(() => {
        if (capacityData?.areaManagerCapacityChart) {
            const overflowItems = capacityData.areaManagerCapacityChart.map((item: Item) => {
                return {
                    overflowCount: item.overflowCount,
                    overflows: item.overflows || [{}],
                };
            });
            return overflowItems;
        }
        return [];
    }, [capacityData?.areaManagerCapacityChart]);

    const options = useMemo(() => {
        if (areaManager?.areaManager) {
            const options = areaManager?.areaManager.areaGroups.map((item: AreaGroupDto) => {
                return {
                    label: item.description,
                    value: item.id,
                };
            });
            return options;
        }
        return [];
    }, [areaManager?.areaManager]);

    const data = {
        labels: labels,
        datasets: [
            {
                label: t('capacity.minOccupation'),
                data: minUsage,
                backgroundColor: theme.palette.themePalette.text,
                stack: 'Stack 0',
                borderColor:  theme.palette.themePalette.opaqueWhite,
                borderWidth: {top: 2},
            },
            {
                label: t('capacity.maxOccupation'),
                data: maxUsage,
                backgroundColor: theme.palette.themePalette.primary,
                stack: 'Stack 0',
                borderRadius: 6
            },
            {
                type: 'line' as const,
                label: t('capacity.maxCapacity'),
                data: maxCapacity,
                stack: 'Stack 0',
                stepped: 'middle',
                pointStyle: 'line',
                borderColor: theme.palette.secondary.main,
                borderWidth: 2,
                fill: false,
            },
        ],
    };

    const handleChange = (value: DateRange<Date>) => {
        const startDate = value[0];
        const endDate = value[1];

        if (startDate && endDate) {
            setStartDate(DateTime.fromJSDate(startDate).toISODate() + 'T00:00');
            setEndDate(DateTime.fromJSDate(endDate).toISODate() + 'T00:00');
        }
    };

    const handleSelectChange = (event: string | number) => {
        if (event === 'null') {
            setAreaGroup(null);
        }
        setAreaGroup(event);
    };

    const renderToolTip = (index: number): JSX.Element => {
        const item = overflowItems[index];
        if (!item) return <React.Fragment/>;

        return <BarChartToolTip
            title={areaManager?.areaManager.description ?? t("areas.areaManagerName")}
            subTitle={t("areas.areaManagerName")}
            count={item.overflowCount}
            rows={item.overflows}/>;
    }

    return (
        <DefaultLayout>
            <React.Fragment>
                <TopWrap>
                    <Breadcrumbs separator={<NextIcon fontSize="small" />} aria-label="breadcrumb">
                        <BreadcrumbLink href="/">{t('general.areas')}</BreadcrumbLink>

                        <Typography variant="h1">{areaManager?.areaManager.description}</Typography>
                    </Breadcrumbs>
                </TopWrap>

                <BarChartActionBar>
                    <div>
                        <SelectField
                            margin="none"
                            onChange={handleSelectChange}
                            options={options}
                            label={t('areas.group')}
                            name="updateType"
                            placeholder={t('capacity.allgroups')}
                        />
                    </div>
                    <Typography variant="h2">{`${t('general.last')} ${labels.length} ${t('general.days')}`}</Typography>
                    <DateRangePicker
                        defaultCalendarMonth={new Date(date.setMonth(date.getMonth() - 2))}
                        disableFuture={true}
                        onChange={handleChange}
                        daysSelected={days}
                    />
                </BarChartActionBar>

                <OverflowTooltipContainer>
                    {overflowItems.map((item: OverflowRow, idx) => (
                        <OverflowTooltipWrapper key={idx} style={{width: 100 / labels.length + '%',}}>
                            <OverflowTooltip>{item.overflowCount}</OverflowTooltip>
                        </OverflowTooltipWrapper>
                    ))}
                </OverflowTooltipContainer>

                <BarChart
                    data={data}
                    tooltipRenderer={renderToolTip}
                    legendBottomText={t('general.day')}
                    legendLeftText={t('general.areaCapacity')} />
            </React.Fragment>
            <OverflowTable head={head} data={overflowData} />
        </DefaultLayout>
    );
};

export default AreaManagerCapacityOverview;

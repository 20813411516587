import React from 'react';
import {styled} from '@mui/system';

export type TooltipData = {};

export type Row = {
    label?: string | undefined;
    count?: number | undefined;
};

type Props = {
    title: string;
    subTitle: string;
    count: number | undefined;
    rows: Row[];
};

export const TOOLTIP_WIDTH = 320;

const Header = styled('div')({
    display: 'flex',
    flexDirection: "row",
    width: '100%',
    borderBottom: "1px solid #82819A",
    marginBottom: 12,
    fontSize: 14,

    '& h3': {
        fontWeight: '400',
        marginBottom: '15px',
    },

    '& h3 > span': {
        color: 'rgb(217, 217, 225, 0.7)',
        marginLeft: 4
    },

    '& h3:first-of-type': {
        flex: 1
    },

    '& h3:last-of-type': {
        color: 'rgb(217, 217, 225, 0.7)'
    }
});

const Tooltip = styled('div')(({theme}) => ({
    backgroundColor: "#010032",
    display: 'flex',
    position: 'relative',
    cursor: 'pointer',
    color: "#FFFFFF",
    height: 'auto',
    width: `${TOOLTIP_WIDTH}px`,
    padding: '40px',
    flexDirection: "column",
    borderRadius: 15,
    boxShadow: '0 35px 60px 0 rgba(1,0,50,0.2)',
}));

const ListItem = styled('div')({
    display: 'flex',
    flexDirection: "row",
    width: '100%',
    marginTop: 5,
    marginBottom: 5,

    '& span': {
        fontWeight: '400',
        fontSize: '13px',
    },

    '& span:first-of-type': {
        flex: 1,
        marginRight: '25px'
    },

    '& span:last-of-type': {
        color: 'rgb(217, 217, 225, 0.7)'
    }
});

const BarChartToolTip = (props: Props) => {
    const {title, subTitle, count, rows} = props;

    return (
        <Tooltip>
            <Header><h3>{title}<span>{subTitle}</span></h3><h3>{count}</h3></Header>
            {rows?.map((row: Row, index: number) => (
                <ListItem key={index}>
                    <span>{row.label}</span> <span>{row.count}</span>
                </ListItem>
            ))}
        </Tooltip>
    );
};

export default BarChartToolTip;

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Dashboard from 'components/Dashboard';
import UsersOverview from 'components/UsersOverview';
import Providers from 'components/Providers';
import AreaManagerCapacityOverview from 'components/AreaManagerCapacityOverview';
import AreaDetail from 'components/AreaDetail';
import Messages from 'components/Messages';
import Authorisations from "../Authorisations";

export const DashboardPath = '/';
export const UsersOverviewPath = '/gebruikers';
export const AuthorisationsPath = '/authorisations';
export const ProvidersPath = '/providers';
export const AreaManagerCapacityOverviewPath = '/capacity';
export const AreaPath = '/area';
export const MessagesPath = '/berichten';

const Router = () => {
    return (
        <Routes>
            <Route path={DashboardPath} element={<Dashboard />} />
            <Route path={UsersOverviewPath} element={<UsersOverview />} />
            <Route path={AuthorisationsPath} element={<Authorisations />} />
            <Route path={ProvidersPath} element={<Providers />} />
            <Route path={`${AreaManagerCapacityOverviewPath}/:days/:id`} element={<AreaManagerCapacityOverview />} />
            <Route path={`${AreaPath}/:id`} element={<AreaDetail />} />
            <Route path={MessagesPath} element={<Messages />} />
        </Routes>
    );
};

export default Router;

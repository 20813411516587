import React from 'react';
import Dialog from 'packages/shared/Dialog';
import { useTranslation } from 'react-i18next';
import { useForm } from 'hooks';
import { useDialogSettings } from 'packages/shared/Context/DialogSettings';
import { useSnackbar } from 'packages/shared/Context/Snackbar';
import { useAreaGroupQuery, useUpdateAreaGroup } from 'graph';
import { dialogs } from 'lib';
import { Option } from 'packages/shared/core/MultiSelectField';
import EditGroupForm from 'packages/app/forms/EditGroupForm';
import { WindowSharp } from '@mui/icons-material';

const EditGroupDialog = () => {
    const { t } = useTranslation();
    const [form] = useForm({
        description: true,
        areas: true,
    });
    const dialogSettings = useDialogSettings();
    const groupId = dialogSettings.getData();

    //mutation
    const [mutate] = useUpdateAreaGroup({
        refetchQueries: ['areaManager', 'areas'],
    });

    //snackbars
    const snackbarError = useSnackbar({
        message: t('error.general'),
        severity: 'error',
    });

    const snackbarSucces = useSnackbar({
        message: t('area-group.editSuccess'),
        severity: 'success',
    });

    const { data: groupData } = useAreaGroupQuery({
        variables: {
            id: groupId,
        },
    });

    const areaManagerId = groupData?.areaGroup?.areaManager;

    //functions
    const handleClick = () => {
        form.onSubmit()
            .then((result: any) => {
                const areaIds = result.areas.map((area: Option) => area.value);

                mutate({
                    variables: {
                        input: {
                            ...result,
                            id: groupId,
                            areas: areaIds,
                            areaManagerId: areaManagerId,
                        },
                    },
                })
                    .then((result: any) => {
                        if (result?.data?.updateAreaGroup?.id) {
                            snackbarSucces.onSetAlert();
                            dialogSettings.closeDialog();

                            window.location.reload();
                        }
                    })
                    .catch((e) => {
                        snackbarError.onSetAlert();
                    });
            })
            .catch((e) => {
                snackbarError.onSetAlert();
            });
    };

    const handleClose = () => {
        form.onReset();
    };

    return (
        <Dialog
            title={t('area-group.edit')}
            name={dialogs.editGroup}
            onClick={handleClick}
            onClose={handleClose}
            acceptName={t('area-group.edit')}
        >
            <EditGroupForm form={form} groupId={parseInt(groupId)} />
        </Dialog>
    );
};

export default EditGroupDialog;

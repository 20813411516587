import React from 'react';
import Dialog from 'packages/shared/Dialog';
import { useTranslation } from 'react-i18next';
import { SetForm } from 'packages/app/forms';
import { useForm } from 'hooks';
import { useDialogSettings } from 'packages/shared/Context/DialogSettings';
import { useSnackbar } from 'packages/shared/Context/Snackbar';
import { useCreateCapacitySchedule } from 'graph';
import { dialogs, normalizeNestedFormData } from 'lib';
import { FormObject } from 'hooks/useForm/useForm';
import { toIsoDate } from 'lib'

const CreateSetDialog = () => {
    const { t } = useTranslation();
    const [form] = useForm();
    const dialogSettings = useDialogSettings();
    const areaManagerId = dialogSettings.getData();

    //snackbars
    const snackbarError = useSnackbar({
        message: t('error.general'),
        severity: 'error',
    });
    const snackbarSucces = useSnackbar({
        message: t('areas.create-set-success'),
        severity: 'success',
    });

    //mutation
    const [mutate] = useCreateCapacitySchedule({
        refetchQueries: ['capacitySchedulesByAreaManager']
    });

    //functions
    const handleClick = () => {
        form.onSubmit()
            .then((result: FormObject) => {
                const normalizedData = normalizeNestedFormData(result);

                let providerCapacities = normalizedData.providerCapacities;
                delete normalizedData.providerCapacities;

                providerCapacities = providerCapacities.filter((x: { limit: string; }) => x.limit !== "")

                const finalData = {
                    ...normalizedData,
                    areaManagerCapacity: {
                        ...normalizedData.areaManagerCapacity,
                        providerCapacities
                    },
                    areaManagerId: areaManagerId,
                    startDate: toIsoDate(normalizedData.startDate) + 'T00:00',
                }

                mutate({
                    variables: {
                        input: finalData,
                    },
                })
                    .then((result) => {
                        if (result?.data?.createCapacitySchedule) {
                            snackbarSucces.onSetAlert();
                            dialogSettings.closeDialog();
                        }
                    })
                    .catch(() => {
                        snackbarError.onSetAlert();
                    });
            })
            .catch((e) => {
                snackbarError.onSetAlert();
            });
    };

    const handleClose = () => {
        form.onReset();
    };

    return (
        <Dialog
            title={t('areas.newset')}
            name={dialogs.createSet}
            onClick={handleClick}
            onClose={handleClose}
            acceptName={t('general.create')}
        >
            <SetForm form={form} />
        </Dialog>
    );
};

export default CreateSetDialog;

import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import TextField from '@mui/material/TextField';
import DateRangePicker, { DateRange } from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

type Props = {
    startText?: string;
    endText?: string;
    daysSelected: string;
    disableFuture?: boolean;
    defaultCalendarMonth?: Date;
    onChange: (date: DateRange<Date | null>, keyboardInputValue?: string) => void;
    value?: DateRange<Date>
};

const DateRangePickerField = (props: Props) => {
    const { startText, endText, disableFuture = false, defaultCalendarMonth, onChange, daysSelected } = props;

    const startDate = DateTime.now()
        .minus({ days: Number(daysSelected) })
        .toJSDate();
    const endDate = DateTime.now().toJSDate();

    const { t } = useTranslation();
    const [value, setValue] = useState<DateRange<Date>>(props.value?? [startDate, endDate]);

    const TillText = styled('div')({
        margin: '0 8px',
    });

    const handleChange = (newValue: DateRange<Date>) => {
        if (onChange) onChange(newValue);

        setValue(newValue);
    };
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
                startText={startText || t('general.start')}
                endText={endText || t('general.end')}
                disableFuture={disableFuture}
                value={value}
                defaultCalendarMonth={defaultCalendarMonth}
                onChange={handleChange}
                inputFormat="dd/MM/yyyy"
                mask={'__/__/____'}
                renderInput={(startProps, endProps) => (
                    <React.Fragment>
                        <TextField {...startProps} />
                        <TillText>
                            <Typography variant="caption">{t('general.till')}</Typography>
                        </TillText>
                        <TextField {...endProps} />
                    </React.Fragment>
                )}
            />
        </LocalizationProvider>
    );
};

export default DateRangePickerField;

import {createTheme, Theme} from '@mui/material/styles';
import './theme.css';
import {FlexPalette} from "@mui/system";
import {Palette} from "@mui/material";

declare module '@mui/system' {
    // eslint-disable-next-line
    interface themePaletteColorOptions {
        primary: string;
        secondary: string;
        tertiary: string;
        border: string;
        borderHover: string;
        greyedOut: string;
        extraColor: string;
        primaryHoverColor: string;
        textLight: string;
    }
    // eslint-disable-next-line
    interface themePaletteColor {
        primary: string;
        secondary: string;
        tertiary: string;
        border: string;
        borderHover: string;
        greyedOut: string;
        extraColor: string;
        primaryHoverColor: string;
        textLight: string;
        text: string;
        opaqueWhite: string;
    }
    // eslint-disable-next-line
    interface defaultBackgroundcolorOptions {
        primary: string;
    }
    // eslint-disable-next-line
    interface defaultBackgroundcolor {
        primary: string;
    }
    // eslint-disable-next-line
    interface PaletteOptions {
        themePalette?: themePaletteColorOptions;
        backgroundPalette?: defaultBackgroundcolorOptions;
    }
    // eslint-disable-next-line
    interface FlexPalette extends Palette {
        themePalette: themePaletteColor;
        backgroundPalette: defaultBackgroundcolor;
    }
}

export interface FlexTheme extends Theme {
    palette: FlexPalette
}

let theme = require('./themes/default').theme;
const muiTheme = createTheme(theme);

process.env.NODE_ENV === 'development' && console.log('theme', theme);
export default muiTheme;

import React, { FC, useState, useMemo, createContext, ReactNode } from 'react';
import { Alert, Snackbar } from '@mui/material';

export type AlertType = {
    open?: boolean;
    message?: string;
    severity?: 'error' | 'info' | 'success' | 'warning';
};

const useProviderValue = () => {
    const [alert, setAlert] = useState<AlertType>({ open: false });
    const value = useMemo(
        () => ({
            alert,
            setAlert,
        }),
        [alert]
    );
    return value;
};

export type Context = ReturnType<typeof useProviderValue>;

//Create Context
const SnackbarContext = createContext<Context | undefined>(undefined);
SnackbarContext.displayName = 'SnackbarContext';

type Props = {
    children: ReactNode;
}

//provider
export const SnackbarProvider: FC<Props> = (props) => {
    const value = useProviderValue();

    const handleClose = () => {
        value.setAlert({
            ...value.alert,
            open: false,
        });
    };
    return (
        <SnackbarContext.Provider value={value}>
            {props.children}
            <Snackbar
                open={value.alert.open}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleClose}
                    severity={value.alert.severity || 'info'}
                >
                    {value.alert.message || ''}
                </Alert>
            </Snackbar>
        </SnackbarContext.Provider>
    );
};

export const useContext = () => {
    const context = React.useContext(SnackbarContext);
    if (context === undefined) {
        throw new Error(`useContext must be used within a Provider`);
    }
    return context;
};

export const useHandleClose = () => {
    const { setAlert } = useContext();
    setAlert({
        open: false,
    });
};

export const useSnackbar = (scheme: AlertType) => {
    const { setAlert } = useContext();
    const snackbarFunctions = {
        onSetAlert: (obj: AlertType = { open: true }) => {
            setAlert({
                open: true,
                message: obj.message || scheme.message || '',
                severity: obj.severity || scheme.severity,
            });
        },
    };

    return snackbarFunctions;
};

import React from 'react';
import { TextField } from 'packages/shared/core';
import { useTranslation } from 'react-i18next';
import DateField from 'packages/shared/core/DateField';
import { Divider, styled, Typography } from '@mui/material';
import { useAreaManager, useProviders } from 'graph';
import { useDialogSettings } from 'packages/shared/Context/DialogSettings';
import { FormType } from 'hooks/useForm/types';

const DividerStyled = styled(Divider)({
    margin: '15px 0',
});

const GroupNameTypographyStyled = styled(Typography)({
    marginTop: '4px',
});

const GroupWrapper = styled('div')({
    marginTop: '25px',
    fontSize: 18,
});

const ProvidersWrapper = styled('div')({
    marginTop: '25px',
    fontSize: 18,
});

const FieldsWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',

    '&:nth-of-type(odd)': {
        marginRight: 'auto',
    },
});

type Props = {
    form: FormType
};

const SetForm = (props: Props) => {
    const { form } = props;
    const { t } = useTranslation();
    const dialogSettings = useDialogSettings();

    const areaManagerId = dialogSettings.getData();
    const { data: areaManagerData } = useAreaManager({
        variables: {
            id: areaManagerId,
        },
    });

    const { data: providers } = useProviders({
        variables: {
            pagination: {
                page: 0,
                pageSize: 100,
            },
        },
    });

    return (
        <div>
            <DateField disablePast name='startDate' form={form} placeHolder={t('areas.startDate')} fullWidth />

            <TextField name='areaManagerCapacity.capacity' type='number' form={form} label={t('areas.area-capacity')} fullWidth />

            <TextField name='areaManagerCapacity.maxProviderPercentage' form={form} label={t('areas.area-percentage')} fullWidth />

            <TextField name='areaManagerCapacity.usageId' initialValue={'deelautop'} hidden form={form} label={t('areas.area-percentage')} fullWidth />

            <GroupWrapper>
                <Typography variant='h3'>{t('areas.groups')}</Typography>

                {areaManagerData?.areaManager?.areaGroups.map((areaGroup, index) => (
                    <div key={index}>
                        <GroupNameTypographyStyled>{areaGroup.description}</GroupNameTypographyStyled>

                        <FieldsWrapper>
                            <TextField
                                name={`areaGroupCapacities[${index}].capacity`}
                                type='number'
                                form={form}
                                label={t('areas.capacity')}
                                halfWidth
                            />

                            <TextField
                                name={`areaGroupCapacities[${index}].maxProviderPercentage`}
                                form={form}
                                label={t('general.percentage')}
                                halfWidth
                            />

                            <TextField
                                hidden
                                name={`areaGroupCapacities[${index}].areaGroupId`}
                                type='number'
                                readOnly
                                initialValue={areaGroup.id}
                                form={form}
                                label={t('general.name')}
                                fullWidth
                            />

                            <TextField
                                name={`areaGroupCapacities[${index}].usageId`}
                                form={form}
                                label={t('general.percentage')}
                                fullWidth
                                initialValue={'deelautop'}
                                hidden
                            />
                        </FieldsWrapper>

                        <DividerStyled variant='middle' textAlign='center' />
                    </div>
                ))}
            </GroupWrapper>

            <ProvidersWrapper>
                <Typography variant='h3'>{t('areas.provider-capacities')}</Typography>

                {providers?.providers?.content?.map((provider, index) => (
                    <div key={index}>
                        <FieldsWrapper>
                            <TextField
                                name={`providerCapacities[${index}].limit`}
                                type='number'
                                form={form}
                                label={provider.description + ' ' + t('areas.limit')}
                                halfWidth
                            />

                            <TextField
                                hidden
                                name={`providerCapacities[${index}].providerId`}
                                type='number'
                                readOnly
                                initialValue={provider.providerId}
                                form={form}
                                label={t('general.name')}
                                fullWidth
                            />
                        </FieldsWrapper>

                        {/*<DividerStyled variant='middle' textAlign='center' />*/}
                    </div>
                ))}
            </ProvidersWrapper>
        </div>
    );
};

export default SetForm;

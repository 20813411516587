import React, { useEffect, useState } from 'react';
import { FormControl, FormHelperText, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormLabel } from 'packages/shared/core';
import { styled } from '@mui/system';

const FormWrapStyled = styled(FormControl)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

const SelectStyled = styled(Select)`
    height: '40px',
    marginLeft: ${props => props.label ? '20px': '0'},
    minWidth: '250px',
`;

type Props = {
    onChange?: (event: any) => void;
    options: { value: string; label: string; disabled?: boolean }[];
    label?: string;
    className?: string;
    name: string;
    form?: any;
    hint?: string;
    initialValue?: string | null;
    loading?: boolean;
    readOnly?: boolean;
    margin?: 'dense' | 'none';
    placeholder?: string;
};

const SelectField = (props: Props) => {
    const {
        onChange,
        options = [],
        label,
        name,
        form = false,
        hint,
        initialValue = '',
        loading = false,
        readOnly = false,
        margin = 'none',
        placeholder,
    } = props;
    const { t } = useTranslation();

    const [hasError, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [option, setOption] = useState('null');

    useEffect(() => {
        if (form?.errors?.[name]?.length) {
            setError(true);
            setErrorMessage(form.errors[name]);
        } else {
            setError(false);
            setErrorMessage('');
        }
    }, [form, form.errors, name]);

    useEffect(() => {
        if (initialValue && !loading) {
            setOption(initialValue);
            if (onChange) onChange(initialValue);
            if (form) {
                form?.onFieldChange({ key: name, value: initialValue });
            }
        }
        // eslint-disable-next-line
    }, [loading]);

    const handleChange = (event: SelectChangeEvent<unknown>) => {
        const val = event.target.value;

        if (form) form.onFieldChange({ key: name, value: val });
        setOption(val as string);
        onChange?.(val);
    };

    return (
        <FormWrapStyled error={hasError} margin={margin} placeholder=''>
            {label ? <FormLabel gutterBottom>{label}</FormLabel> : <></>}
            <SelectStyled
                value={option}
                onChange={handleChange}
                labelId={`label-${name}`}
                id={`select-${name}`}
                readOnly={readOnly}
                disabled={readOnly}
                defaultValue=''
            >
                <MenuItem value='null'>
                    <em>{placeholder || t('general.none')}</em>
                </MenuItem>
                {options &&
                    options.map((opt, index) => (
                        <MenuItem key={index} value={opt.value} disabled={opt.disabled}>
                            {opt.label}
                        </MenuItem>
                    ))}
            </SelectStyled>
            {hasError && <FormHelperText>{hasError && errorMessage}</FormHelperText>}
            {hint && <FormHelperText>{hint}</FormHelperText>}
        </FormWrapStyled>
    );
};

export default SelectField;

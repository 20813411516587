import React, { ReactNode } from 'react';
import { styled } from '@mui/system';

const FormLabelStyled = styled('div')({
    fontSize: '14px',
    maxWidth: '125px',
    width: '100%',
});

type Props = {
    children: ReactNode;
    gutterBottom?: boolean;
};

const FormLabel = (props: Props) => {
    const { children } = props;

    return <FormLabelStyled>{children}</FormLabelStyled>;
};

export default FormLabel;

import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { getProperty, getPropertyFromObject } from './lib';
import Pagination from 'packages/shared/Pagination';
import ActionMenu from 'packages/app/ActionMenu';
import { FilterType } from 'hooks/usePagination/usePagination';
import { useTranslation } from 'react-i18next';

export type RowType = {
    id: string | number;
    [propName: string]: string | number | boolean;
};

export type format = {
    data: string;
    format: string;
};

type ActionCallback = (row: RowType, action: string) => void;
type RowCallback = (row: RowType) => void;
type Props = {
    head: string[];
    rowFormat: (string | format)[];
    rows: RowType[];
    filter?: FilterType;
    count: number;
    actions?: string[];
    onActionClick?: ActionCallback;
    onRowClick?: RowCallback;
};

const PaginatedTable = (props: Props) => {
    const { head, rows, rowFormat, filter, count, actions, onRowClick, onActionClick } = props;
    const { t } = useTranslation();

    const handleClick = (row: RowType) => (action: string) => {
        onActionClick?.(row, action);
    };

    const handleRowClick = (row: RowType) => () => {
        onRowClick?.(row);
    };

    if (rows && !rows.length)
        return (
            <div>
                <Typography>{t('general.emptyList')}</Typography>
            </div>
        );
    return (
        <TableContainer>
            <Table aria-label="paginated table">
                <TableHead>
                    <TableRow sx={{ bgcolor: 'none' }}>
                        {head.map((name: string, idx) => (
                            <TableCell key={`${idx}-${name}`} align="left">
                                {name}
                            </TableCell>
                        ))}
                        {actions && <TableCell align="right" />}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, idx) => (
                        <TableRow key={row.id} sx={{ bgcolor: idx % 2 === 1 ? 'transparent' : '#D9D9E1' }}>
                            {rowFormat.map((format: string | format, idx) =>
                                typeof format === 'string' ? (
                                    <TableCell component="th" scope="row" key={format} align="left" onClick={handleRowClick(row)}>
                                        {getProperty(row, format)}
                                    </TableCell>
                                ) : (
                                    <TableCell component="th" scope="row" key={format.data} onClick={handleRowClick(row)} align="left">
                                        {getPropertyFromObject(row, format)}
                                    </TableCell>
                                )
                            )}
                            {actions && (
                                <TableCell component="th" scope="row" align="right">
                                    <ActionMenu actions={actions} onClick={handleClick(row)} />
                                </TableCell>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {filter && <Pagination query={filter} count={count} />}
        </TableContainer>
    );
};

export default PaginatedTable;

import React, { useMemo } from 'react';
import Dialog from 'packages/shared/Dialog';
import { useTranslation } from 'react-i18next';
import { AddUserForm } from 'packages/app/forms';
import { useForm } from 'hooks';
import { useDialogSettings } from 'packages/shared/Context/DialogSettings';
import { useSnackbar } from 'packages/shared/Context/Snackbar';
import { useUpdateUser, useUser } from 'graph';
import { dialogs } from 'lib';

const EditUserDialog = () => {
    const { t } = useTranslation();
    const [form] = useForm({
        firstName: true,
        lastName: true,
        email: true,
    });
    const dialogSettings = useDialogSettings();

    const dialogId = useMemo(() => {
        return dialogSettings.getData();
    }, [dialogSettings]);

    //snackbars
    const snackbarError = useSnackbar({
        message: t('error.general'),
        severity: 'error',
    });
    const snackbarSucces = useSnackbar({
        message: t('users.editsuccess'),
        severity: 'success',
    });

    //mutation
    const [mutate] = useUpdateUser({
        refetchQueries: ['users'],
    });

    const { data } = useUser({
        variables: {
            id: dialogId,
        },
    });

    const fetchUser = useMemo(() => {
        if (data && data.user) {
            return {
                ...data.user,
            };
        }
    }, [data]);

    //functions
    const handleClick = () => {
        form.onSubmit()
            .then((result) => {
                mutate({
                    variables: {
                        input: { ...result, id: dialogId },
                    },
                })
                    .then((result) => {
                        if (result?.data?.updateUser?.id) {
                            snackbarSucces.onSetAlert();
                            dialogSettings.closeDialog();
                        }
                    })
                    .catch(() => {
                        snackbarError.onSetAlert();
                    });
            })
            .catch((e) => {
                snackbarError.onSetAlert();
            });
    };

    const handleClose = () => {
        form.onReset();
    };

    return (
        <Dialog title={t('users.edit')} name={dialogs.editUser} onClick={handleClick} onClose={handleClose} acceptName={t('general.edit')}>
            <AddUserForm initial={fetchUser} form={form} />
        </Dialog>
    );
};

export default EditUserDialog;

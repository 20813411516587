import React, { useMemo, useEffect } from 'react';
import Dialog from 'packages/shared/Dialog';
import { useTranslation } from 'react-i18next';
import { useDialogSettings } from 'packages/shared/Context/DialogSettings';
import { useSnackbar } from 'packages/shared/Context/Snackbar';
import { useDeleteUser, useUser } from 'graph';
import { Typography } from '@mui/material';
import { dialogs } from 'lib';

const DeleteUserDialog = () => {
    const { t } = useTranslation();
    const dialogSettings = useDialogSettings();

    const dialogId = useMemo(() => {
        return dialogSettings.getData();
    }, [dialogSettings]);

    //snackbars
    const snackbarError = useSnackbar({
        message: t('error.general'),
        severity: 'error',
    });
    const snackbarSucces = useSnackbar({
        message: t('users.deletesuccess'),
        severity: 'success',
    });

    //mutation
    const [mutate, mutationData] = useDeleteUser({
        refetchQueries: ['users'],
    });

    const handleClick = () => {
        mutate({
            variables: {
                id: dialogId,
            },
        });
    };

    const { data } = useUser({
        variables: {
            id: dialogId,
        },
    });

    //use effect
    useEffect(() => {
        if (!mutationData.loading) {
            if (mutationData.error) {
                snackbarError.onSetAlert();
                dialogSettings.closeDialog();
            }
            if (mutationData.data && mutationData.data.deleteUser && mutationData.data.deleteUser === true) {
                snackbarSucces.onSetAlert({
                    message: t('users.deletesuccess'),
                });
                dialogSettings.closeDialog();
            }
        }
        // eslint-disable-next-line
    }, [mutationData.data, mutationData.error, mutationData.loading]);

    const user = useMemo(() => {
        if (data && data.user) {
            return {
                ...data.user,
            };
        }
    }, [data]);

    return (
        <Dialog title={t('users.delete')} name={dialogs.deleteUser} onClick={handleClick} acceptName={t('general.delete')}>
            <Typography variant="h4" gutterBottom>
                {t('general.warning')}
            </Typography>
            <Typography variant="body1">
                {t('users.deleteUserDialogContent')}
                {user?.firstName && user?.lastName ? ` ${user?.firstName} ${user?.lastName} ` : t('users.thisuser')}
                {t('users.deleteUserDialogContent2')}
            </Typography>
        </Dialog>
    );
};

export default DeleteUserDialog;

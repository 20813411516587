import React, { useState, useEffect } from 'react';
import { TextField } from 'packages/shared/core';
import { useTranslation } from 'react-i18next';
import { MultiSelectField } from 'packages/shared/core';
import { useAreaGroupQuery, useAreas } from 'graph';
import { usePagination } from 'hooks';
import { FormControlLabel, Switch, Collapse } from '@mui/material';
import AddAreaForm from 'packages/app/forms/AddAreaForm';
import { Option } from 'packages/shared/core/MultiSelectField';
import { FormType } from 'hooks/useForm/types';

type Props = {
    form: FormType;
    areaManagerId?: string;
    groupId?: number;
};

const AddGroupForm = (props: Props) => {
    const { form, groupId } = props;
    const { t } = useTranslation();
    const [options, setOptions] = useState<Option[]>([]);
    const [initialValue, setInitialValue] = useState<Option[]>([]);
    const newAreaText = t('area.new');

    const { data: groupData } = useAreaGroupQuery({
        variables: {
            id: groupId,
        },
    });

    const { data: areasData, loading } = useAreas({
        variables: {
            pagination: {
                pageSize: 9999,
                page: 0,
            },
            filter: {
                areaManagerId: groupData?.areaGroup.areaManager,
                onlyNotLinked: false,
            },
        },
    });

    const formatAreasArray = (areas: any) => {
        const formattedAreasArray = areas.map((area: any) => {
            return {
                label: `${area.areaId || ''}`,
                value: area.id,
            };
        });
        return formattedAreasArray;
    };

    useEffect(() => {
        if (groupData?.areaGroup?.areas?.length && !loading) {
            const formattedAreasArray = formatAreasArray(groupData?.areaGroup?.areas);
            setInitialValue(formattedAreasArray);
        }
        if (areasData?.areas.content?.length) {
            const formattedAreasArray = formatAreasArray(areasData?.areas?.content);
            setOptions(formattedAreasArray);
        }
    }, [areasData, loading, groupData]);

    const [showAreaForm, setShowAreaForm] = useState<boolean>(false);

    const handleShowAreaFormChange = () => {
        setShowAreaForm((prev) => !prev);
    };

    const handleShowAreaForm = (show: boolean) => {
        setShowAreaForm(show);
    };

    return (
        <div>
            <TextField
                className="test"
                name="description"
                form={form}
                label={t('general.name')}
                fullWidth
                initialValue={groupData?.areaGroup?.description}
            />
            <MultiSelectField
                name="areas"
                form={form}
                options={options}
                optionLabelName={'areaId'}
                placeholder={t('areas.codes')}
                label={''}
                initialValue={initialValue}
            />

            <FormControlLabel control={<Switch checked={showAreaForm} onChange={handleShowAreaFormChange} />} label={newAreaText} />

            <Collapse in={showAreaForm}>
                <AddAreaForm onSetShowAreaForm={handleShowAreaForm} areaManagerId={groupData?.areaGroup.areaManager ?? undefined} />
            </Collapse>
        </div>
    );
};

export default AddGroupForm;

import React from 'react';
import ListContainer from './List';
import { useTranslation } from 'react-i18next';
import { DashboardPath, UsersOverviewPath, AuthorisationsPath, ProvidersPath, MessagesPath } from 'components/Router/Router';
import { styled } from '@mui/system';

const NavWrapper = styled('div')(({ theme }) => ({
    flex: '0 0 100%',
    paddingTop: '40px',
}));

const Menu = () => {
    const { t } = useTranslation();

    return (
        <NavWrapper>
            <ListContainer
                list={[
                    {
                        name: t('general.areas'),
                        path: DashboardPath,
                    },
                    {
                        name: t('general.sharecarproviders'),
                        path: ProvidersPath,
                    },
                    {
                        name: t('general.authorisations'),
                        path: AuthorisationsPath,
                    },
                    {
                        name: t('general.users'),
                        path: UsersOverviewPath,
                    },
                    {
                        name: t('general.messages'),
                        path: MessagesPath,
                    },
                ]}
            />
        </NavWrapper>
    );
};

export default Menu;

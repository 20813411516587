import { OverflowGroupDto } from 'graph';

import i18n from 'locales/i18n';

export const head = [
    i18n.t('areas.group'),
    i18n.t('capacity.managerMaxCap'),
    i18n.t('capacity.groupMaxCap'),
    i18n.t('capacity.providerManagerMaxCap'),
    i18n.t('capacity.providerGroupMaxCap'),
];

export type Item = OverflowGroupDto;

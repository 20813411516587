import React, { useState, useMemo, createContext, ReactNode } from 'react';

//build provider props and functions
const useProviderValue = () => {
    const [dialogName, setDialogName] = useState('');
    const [data, setData] = useState('');
    const value = useMemo(
        () => ({
            dialogName,
            setDialogName,
            data,
            setData,
        }),
        [dialogName, data]
    );
    return value;
};

//create contextType
export type Context = ReturnType<typeof useProviderValue>;

//Create Context
const DialogSettingsContext = createContext<Context | undefined>(undefined);
DialogSettingsContext.displayName = 'DialogSettingsContext';

type Props = {
    children: ReactNode;
}

//Create provider
export const DialogSettingsProvider: React.FC<Props> = (props) => {
    const value = useProviderValue();
    return <DialogSettingsContext.Provider value={value} {...props} />;
};

export const useContext = () => {
    const context = React.useContext(DialogSettingsContext);
    if (context === undefined) {
        throw new Error(`useContext must be used within a Provider`);
    }
    return context;
};

export const useGetDialogName = () => {
    const { dialogName } = useContext();
    return dialogName;
};

export const useGetData = () => {
    const { data } = useContext();
    return data;
};

export const useDialogSettings = () => {
    const { setDialogName, setData, data, dialogName } = useContext();

    const dialogSettings = {
        openDialog: (name: string, value?: string) => {
            setDialogName(name);
            setData(value || '');
        },
        onSetData: (value: string) => {
            setData(value);
        },
        getData: () => {
            return data;
        },
        getDialogName: () => {
            return dialogName;
        },
        closeDialog: () => {
            setDialogName('');
            setData('');
        },
    };
    return dialogSettings;
};

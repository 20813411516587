import React from 'react';
import { CircularProgress } from '@mui/material';
import { styled } from '@mui/system';

const LoaderWrap = styled('div')({
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 99,
});

type Props = {
    color?: 'inherit' | 'primary' | 'secondary';
};
const Loader = (props: Props) => {
    const { color = 'primary' } = props;

    return (
        <LoaderWrap>
            <CircularProgress color={color} />
        </LoaderWrap>
    );
};

export default Loader;

import React from 'react';
import { TextField } from 'packages/shared/core';
import { useTranslation } from 'react-i18next';

type Props = {
    form: any;
};

const AddProviderForm = (props: Props) => {
    const { form } = props;
    const { t } = useTranslation();

    return (
        <div>
            <TextField
                name="providerId"
                form={form}
                label={t('provider.providerId')}
                fullWidth
            />

            <TextField
                name="description"
                form={form}
                label={t('general.description')}
                fullWidth
            />
        </div>
    );
};

export default AddProviderForm;

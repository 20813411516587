import React from 'react';
import ActionMenu from 'packages/app/ActionMenu';
import {useDeleteAreaManager, AREA_MANAGERS_QUERY} from 'graph';
import {PercentageBar} from 'packages/app/PercentageBar';
import {t} from 'i18next';
import Loader from 'packages/shared/core/Loader';
import {styled} from '@mui/system';

export type RowType = {
    id: string | number;
    capacity: number;
    occupation: number;
    description: string;
    overflowCount1Day: number;
    overflowCount7Days: number;
    overflowCount28Days: number;
};

type RowCallback = (row: RowType) => void;
export type Props = {
    onRowClick?: RowCallback;
    onDayClick?: (days: number, rowId: string) => void;
    rows: RowType[];
    head: string[];
    loading?: boolean;
};

const DefaultCapacityTable = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
    overflow: 'hidden',
});

const PercentageBarStyled = styled(PercentageBar)({
    '&.percentage-bar': {
        maxWidth: '590px',
        marginRight: '75px',
    },
});

const TableHead = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '25px',
    justifyContent: 'space-between',
});

const TableHeadItem = styled('div')(({theme}) => ({
    '&:first-of-type': {
        width: '36px',
        textAlign: 'left',
    },

    '&:nth-of-type(2)': {
        width: '90px',
        textAlign: 'left',
        marginLeft: '4px'
    },

    '&:nth-of-type(3)': {
        width: '150px',
        textAlign: 'left',
    },

    '&:nth-of-type(4)': {
        width: '295px',
        textAlign: 'left',
    },

    '&:nth-of-type(5)': {
        width: '280px',
        textAlign: 'right',
        marginRight: '70px',
    },

    width: '70px',
    textAlign: 'center',
    fontWeight: '500',
    fontSize: '15px',
    color: theme.palette.themePalette.primary,
}));

const TableRows = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

const TableRowItem = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '20px',
    justifyContent: 'space-between',
    hyphens: 'auto',
    fontSize: '14px',

    '&:last-of-type': {
        marginBottom: '0',
    },
});

const IdItem = styled('div')(({theme}) => ({
    width: '90px',
    fontSize: '14px',

    '&:hover': {
        cursor: 'pointer',
        color: theme.palette.primary.main,
    },
}));

const CityItem = styled('div')(({theme}) => ({
    width: '150px',
    fontSize: '14px',

    '&:hover': {
        cursor: 'pointer',
        color: theme.palette.primary.main,
    },
}));

const AmountItem = styled('div')({
    width: '70px',
    cursor: 'pointer',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',

});

const HoverableColumn = styled('div')(({ theme }) => ({
    textTransform: 'capitalize',

    '&:hover': {
        backgroundColor: theme.palette.themePalette.opaqueWhite,
        width: 'fit-content',
        padding: '12px 15px',
        borderRadius: '6px',
        marginLeft: '-15px',
    },
}));

const AmountItemGrey = styled('div')(({theme}) => ({
    width: '70px',
    textAlign: 'center',
    backgroundColor: theme.palette.backgroundPalette.primary,
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    fontSize: '14px',

    '&::after': {
        content: "''",
        position: 'absolute',
        display: 'block',
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.backgroundPalette.primary,
        bottom: '-20px',
        zIndex: '-1',
    },
}));

const DenialsBlock = styled('div')(({theme}) => ({
    width: '230px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginBottom: '15px',
    alignSelf: 'flex-end',
    color: theme.palette.themePalette.text,
    fontSize: '14px',
    position: 'relative',
    height: '25px',
    overflow: 'hidden',

    '& .titleBlock': {
        background: theme.palette.themePalette.white,
        position: 'relative',
        zIndex: '2',
        padding: '0 15px',
    },

    '&::after': {
        content: '""',
        border: `1px solid ${theme.palette.themePalette.borderDark}`,
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '9px',
    },
}));

const HoverableAmount = styled('div')(({theme}) => ({
    padding: '12px 15px',
    cursor: 'pointer',

    '&:hover': {
        boxSizing: 'border-box',
        border: `1px solid ${theme.palette.themePalette.border}`,
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white'
    },
}));

const CapacityScheduleTable = (props: Props) => {
    const actions = [t('general.delete')];
    const [deleteAreaManager] = useDeleteAreaManager();
    const {rows, head, onRowClick, onDayClick, loading} = props;

    const handleRowClick = (row: RowType) => () => {
        onRowClick?.(row);
    };

    const handleDayClick = (days: number, rowId: string) => () => {
        onDayClick?.(days, rowId);
    };

    const makeClickHandler = (id: string) => (action: string) => {
        switch (action) {
            case t('general.delete'):
                if (!id) return;
                deleteAreaManager({
                    variables: {
                        id,
                    },
                    refetchQueries: [AREA_MANAGERS_QUERY],
                });
                break;
            default:
                throw new Error(t('error.general'));
        }
    };

    if (loading) return <Loader/>;
    return (
        <DefaultCapacityTable>
            <DenialsBlock>
                <div className="titleBlock">{t<string>('general.totalDenials')}</div>
            </DenialsBlock>
            <TableHead>
                {head.map((name: string, idx) => (
                    <TableHeadItem key={`${idx}-${name}`}>{name}</TableHeadItem>
                ))}
            </TableHead>
            <TableRows>
                {rows.map((row: RowType) => (
                    <TableRowItem key={row.id}>
                        <ActionMenu actions={actions} onClick={makeClickHandler(row.id as string)}/>
                        <IdItem onClick={handleRowClick(row)}><HoverableColumn>{row.id}</HoverableColumn></IdItem>
                        <CityItem onClick={handleRowClick(row)}><HoverableColumn>{row.description}</HoverableColumn></CityItem>
                        <PercentageBarStyled
                            className={'percentage-bar'}
                            capacity={row.capacity}
                            occupation={row.occupation}
                        />
                        <AmountItemGrey><HoverableAmount onClick={handleDayClick(1, row.id as string)}>{row.overflowCount1Day}</HoverableAmount></AmountItemGrey>
                        <AmountItem><HoverableAmount onClick={handleDayClick(7, row.id as string)}>{row.overflowCount7Days}</HoverableAmount></AmountItem>
                        <AmountItemGrey><HoverableAmount onClick={handleDayClick(28, row.id as string)}>{row.overflowCount28Days}</HoverableAmount></AmountItemGrey>
                    </TableRowItem>
                ))}
            </TableRows>
        </DefaultCapacityTable>
    );
};

export default CapacityScheduleTable;

import React, { useState } from 'react';
import DateField from 'packages/shared/core/DateField';
import TextField from "packages/shared/core/TextField";
import { useTranslation } from 'react-i18next';
import { useDialogSettings } from 'packages/shared/Context/DialogSettings';

type Props = {
    form: any;
};

const CancelAuthorisationForm = (props: Props) => {
    const { t } = useTranslation();
    const dialogSettings = useDialogSettings();
    const paymentAuthorisationId = dialogSettings.getData();
    const [date] = useState<Date>(new Date());

    return (
        <div>
            <TextField name="paymentAuthorisationId" label={t('authorisations.table-header.payment-authorisation-id')} value={paymentAuthorisationId} fullWidth readOnly />
            <DateField initialValue={date} name="endDate" form={props.form} label={t('authorisation.cancel-form.end-date')} fullWidth />
        </div>
    );
};

export default CancelAuthorisationForm;
